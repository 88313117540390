import React from "react"
import { Icon } from "types/components"

export const FaqIcon: Icon = ({ color, size = 24, ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			fill="none"
			viewBox="0 0 22 22"
			{...props}
		>
			<path
				stroke="url(#paint0_linear_6989_41443)"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M8.09 8a3 3 0 015.83 1c0 2-3 3-3 3m.08 4h.01M21 11c0 5.523-4.477 10-10 10S1 16.523 1 11 5.477 1 11 1s10 4.477 10 10z"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_6989_41443"
					x1="1"
					x2="23.352"
					y1="1"
					y2="4.114"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color ?? "#1753E2"}></stop>
					<stop offset="1" stopColor={color ?? "#F39244"}></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
