import React from "react"
import { Icon } from "types/components"

export const SecurityIcon: Icon = ({ color, size = 24, ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			fill="none"
			viewBox="0 0 24 24"
			{...props}
		>
			<path
				stroke="url(#paint0_linear_7716_42677)"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M19 11H5a2 2 0 00-2 2v7a2 2 0 002 2h14a2 2 0 002-2v-7a2 2 0 00-2-2z"
			></path>
			<path
				stroke="url(#paint1_linear_7716_42677)"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M7 11V7a5 5 0 1110 0v4"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_7716_42677"
					x1="3"
					x2="21"
					y1="16.5"
					y2="16.5"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color ?? "#1753E2"}></stop>
					<stop offset="1" stopColor={color ?? "#F39244"}></stop>
				</linearGradient>
				<linearGradient
					id="paint1_linear_7716_42677"
					x1="7"
					x2="17"
					y1="6.5"
					y2="6.5"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color ?? "#1753E2"}></stop>
					<stop offset="1" stopColor={color ?? "#F39244"}></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
