import React from "react"
import { Icon } from "types/components"

export const CareersIcon: Icon = ({ color, size = 24, ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 22 20"
			width={size}
			{...props}
		>
			<path
				stroke="url(#paint0_linear_6993_41449)"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M15 19V3a2 2 0 00-2-2H9a2 2 0 00-2 2v16M3 5h16a2 2 0 012 2v10a2 2 0 01-2 2H3a2 2 0 01-2-2V7a2 2 0 012-2z"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_6993_41449"
					x1="-0.5"
					x2="13.375"
					y1="-5.5"
					y2="-7.648"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color ?? "#1753E2"}></stop>
					<stop offset="1" stopColor={color ?? "#F39244"}></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
