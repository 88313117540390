import React from "react"
import { ArrowRight } from "react-feather"
import { Chip, Container, PrimaryBtn } from "components/library/components"
import { Link } from "gatsby"
import { useBlog } from "utils/blogs"
import { Breadcrumbs } from "components/core"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { toLowerTraincase } from "utils/general"

type P = {
	article: any
}

export const FeaturedArticle = ({ article }: P) => {
	const {
		link,
		title,
		publishedDate,
		timeToRead,
		author,
		categories,
		featuredImage,
	} = useBlog(article)
	const { t } = useTranslation()

	return (
		<section className="bg-gradient-to-b from-theme-blue">
			<Container className="py-12 flex flex-col gap-10 lg:gap-20">
				<Breadcrumbs className="text-white" type="home" />

				<div className="flex flex-col gap-8">
					<h2 className="hidden lg:block text-white text-5xl">
						{t("common.words.featured")}
					</h2>

					<div className="flex flex-col lg:flex-row rounded-lg lg:text-inherit overflow-hidden relative shadow-md">
						<Link to={link} className="w-full h-64 lg:h-auto lg:w-[55%] bg-white">
							<img
								src={featuredImage}
								loading="eager"
								alt={title}
								className="w-full h-full object-contain"
							/>
						</Link>

						<div className="flex flex-col h-full bg-white w-full lg:w-[55%] p-8 gap-3 lg:gap-6">
							<p className="text-[#9FA8B7] pb-4 lg:pb-8">
								{publishedDate} · {timeToRead}
							</p>

							<div className="flex gap-2 flex-wrap">
								{categories.map((category) => {
									return (
										<Link
											key={category}
											to={`/category/${toLowerTraincase(category)}`}
										>
											<Chip label={category} />
										</Link>
									)
								})}
							</div>

							<Link to={link}>
								<h2 className="text-4xl text-neutral-700 lg:text-5xl break-words">
									{title}
								</h2>
							</Link>

							{/* <p className='text-lg lg:text-2xl text-neutral-500 font-normal'>{excerpt}</p> */}

							<div className="flex-initial flex flex-col lg:flex-row justify-between items-center mt-6 lg:mt-12 gap-4">
								<div className="w-64 flex space-x-4">
									<img
										src={author.image}
										alt={author.name}
										className="rounded-full w-12 h-12"
									/>
									<div>
										<p className="text-lg font-semibold">{author.name}</p>
										<p className="text-sm font-light">{author.bio}</p>
									</div>
								</div>

								<Link to={link} className="w-full lg:w-auto">
									<PrimaryBtn
										tabIndex={-1}
										className="flex items-center gap-2 shadow-md bg-theme-orange hover:bg-theme-orange/80 w-full lg:w-auto justify-center"
									>
										<span>{t("common.cta.read-more")}</span>
										<ArrowRight size={18} />
									</PrimaryBtn>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</Container>
		</section>
	)
}
