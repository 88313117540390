import { Link } from 'gatsby'
import React from "react"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { getSiteBaseUrl } from 'utils/urls'

type P = {
    data: any[]
    filters: string[]
    query: string
}

export const BlogsSearchingResultBlock = ({ data, filters, query }: P) => {
    const { t } = useTranslation()

    return (
        <div>
            {data && data.length === 0 && (
                <div className="text-center text-neutral-500 flex flex-col gap-6 py-8">
                    <h2 className='text-3xl text-neutral-900'>{t('common.messages.no-results-found')} "{query}"</h2>
                    <p>{t('common.messages.nothing-matched-search-term')}</p>
                    <span>
                        {t('common.words.need-help')} <Link className='text-theme-blue' to={`${getSiteBaseUrl(process.env.GATSBY_LANGUAGE_SELECTED)}/contact-us`}>{t('common.words.contact-us')}</Link>
                    </span>
                </div>
            )}

            {data && data.length > 0 && query && (
                <div className='flex justify-center'>
                    <h2 className='text-3xl text-neutral-900'>{data.length} {data.length > 1 ? t('common.words.results') : t('common.words.result')} {t('common.messages.found-for')} "{query}"</h2>
                </div>
            )}

            {data && data.length > 0 && !query && filters.length > 0 && (
                <div className='flex justify-center'>
                    <h2 className='text-3xl text-neutral-900'>{data.length} {data.length > 1 ? 'results' : 'result'} found</h2>
                </div>
            )}
        </div>
    )
}
