import './src/styles/global.css';
import React from "react";
import Layout from "./src/components/core/layout/layout";
import { LiveChatLoaderProvider } from 'react-live-chat-loader'
import { Seo } from "./src/components/core";

export function wrapRootElement({ element }) {
    return (
        <LiveChatLoaderProvider providerKey="xumvd7ya" provider="intercom">
            {element}
        </LiveChatLoaderProvider>
    )
}

const Wrapper = ({ children, ...props }) => {
    return (
        <Seo {...props}>
            <Layout {...props}>
                {children}
            </Layout>
        </Seo>
    )
}

export function wrapPageElement({ element, props }) {
    const newElement = React.cloneElement(
        element,  // I18nextProvider
        element.props,
        React.cloneElement(
            element.props.children,  // I18nextContext.Provider
            element.props.children.props,
            React.createElement(
                Wrapper,
                props,
                element.props.children.props.children,
            ),
        ),
    );

    return newElement;
}