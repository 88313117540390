import React from "react"
import { Icon } from "types/components"

export const PricingIcon: Icon = ({ color, size = 24, ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 20 20"
			width={size}
			{...props}
		>
			<path
				fill="url(#paint0_linear_6979_41444)"
				stroke="url(#paint1_linear_6979_41444)"
				strokeWidth="0.5"
				d="M.91 11.931h0l-.003.003c-.21.21-.21.55 0 .76l6.778 6.777.001.002h.001c.21.21.55.21.76 0L19.47 8.452s0 0 0 0a.536.536 0 00.157-.446h0l-.707-6.05a.538.538 0 00-.474-.492s0 0 0 0l-6.05-.707a.537.537 0 00-.462.151L.91 11.931zm1.137.383L12.51 1.85l5.39.63.63 5.39L8.067 18.334l-6.02-6.02z"
			></path>
			<path
				fill="url(#paint2_linear_6979_41444)"
				d="M13.929 6.78a1.994 1.994 0 01-.586-1.414c0-.512.195-1.023.586-1.414.39-.39.902-.586 1.414-.586.512 0 1.024.196 1.414.586.39.39.586.902.586 1.414 0 .512-.195 1.024-.586 1.414-.39.391-.902.586-1.414.586a1.994 1.994 0 01-1.414-.586zm.13-1.414a1.28 1.28 0 001.284 1.284 1.28 1.28 0 001.284-1.284 1.28 1.28 0 00-2.192-.908c-.25.251-.376.58-.376.908z"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_6979_41444"
					x1="1"
					x2="21.543"
					y1="1"
					y2="3.862"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color ?? "#1753E2"}></stop>
					<stop offset="1" stopColor={color ?? "#F39244"}></stop>
				</linearGradient>
				<linearGradient
					id="paint1_linear_6979_41444"
					x1="1"
					x2="21.543"
					y1="1"
					y2="3.862"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color ?? "#1753E2"}></stop>
					<stop offset="1" stopColor={color ?? "#F39244"}></stop>
				</linearGradient>
				<linearGradient
					id="paint2_linear_6979_41444"
					x1="13.343"
					x2="17.814"
					y1="3.366"
					y2="3.989"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color ?? "#1753E2"}></stop>
					<stop offset="1" stopColor={color ?? "#F39244"}></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
