import { Link } from 'gatsby'
import React, { ComponentPropsWithoutRef } from "react"
import { mergeClasses } from 'utils'
import { BlogCard } from '../blog-card'
import { ArrowRight } from 'react-feather'
import { useTranslation } from 'gatsby-plugin-react-i18next'

type P = ({
    type: 'default'
    title: string
    subtitle: string
    blogs: any[]
} | {
    type: 'category'
    title: string
    subtitle: string
    viewAllUrl: string
    blogs: any[]
} | {
    type: 'browse'
    blogs: any[]
}) & ComponentPropsWithoutRef<'section'>


function removeCustomProps(props: P) {
    const { blogs, ...rest } = props

    if (rest.type === 'category') {
        const { viewAllUrl, title, type, subtitle, ...result } = rest
        return result
    }
    if (rest.type === 'browse') {
        const { type, ...result } = rest
        return result
    }

    const { type, title, subtitle, ...result } = rest
    return result
}

export const BlogsWrapper = ({ className = '', ...props }: P) => {
    const { t } = useTranslation()

    return (
        <section
            className={mergeClasses({ classes: ['flex flex-col gap-12', className] })}
            {...removeCustomProps(props)}
        >
            {props.type !== 'browse' && (
                <div className='flex gap-6 justify-between'>
                    <div className='flex flex-col gap-2'>
                        <h2 className='text-4xl text-theme-blue'>{props.title.includes('/') ? props.title.toLocaleUpperCase() : props.title}</h2>
                        <p className='text-2xl text-neutral-500'>{props.subtitle}</p>
                    </div>

                    {props.type === 'category' && (
                        <Link to={props.viewAllUrl} className="flex items-center gap-2 shrink-0 text-theme-blue">
                            <span>
                                {t('common.words.view-all')}
                            </span>
                            <ArrowRight size={16} strokeWidth={3} />
                        </Link>
                    )}
                </div>
            )}

            {props.type === 'default' && (
                <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
                    {props.blogs.map((blog) => {
                        return <BlogCard key={blog.id} className="max-w-full" type='default' post={blog} />
                    })}
                </div>
            )}

            {props.type === 'category' && (
                <div className='grid md:grid-cols-2 md:grid-rows-2 gap-8'>
                    {props.blogs.map((blog, i) => {
                        return <BlogCard
                            key={blog.id}
                            imageClass={i === 0 ? 'h-auto sm:h-auto' : ''}
                            className={i === 0 ? 'row-span-2 max-w-full col-span-1' : 'max-w-full'}
                            post={blog}
                            type={i === 0 ? "default" : 'no-image'}
                        />
                    })}
                </div>
            )}

            {props.type === 'browse' && (
                <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
                    {props.blogs.map((blog) => {
                        return <BlogCard key={blog.id} className='max-w-full' type='default' post={blog} />
                    })}
                </div>
            )}
        </section>
    )
}
