import { useCallback, useMemo } from "react"
import { getCanonicalPageId, getPageProperty, getPageTitle } from "notion-utils"
import { useTranslation } from "gatsby-plugin-react-i18next"

type Properties =
    | "Cover Image"
    | "Article Title"
    | "Categories"
    | "Time to Read"
    | "Author Image"
    | "Author"
    | "Excerpt"
    | "Author Bio"
    | "Published Date"
    | "Featured Image"

export const useBlog = (post: any) => {
    const { t } = useTranslation();

    const getProperty = useCallback(
        (propertyName: Properties) => {
            const block = post.block[post.id]?.value;

            if (propertyName === "Published Date") {
                const collection = post.collection[block.parent_id]?.value;

                if (collection) {
                    const propertyId = Object.keys(collection.schema).find(
                        (key) =>
                            collection.schema[key]?.name?.toLowerCase() ===
                            propertyName.toLowerCase()
                    );

                    if (propertyId) {
                        const { type } = collection.schema[propertyId];

                        if (type === "date") {
                            const property = block.properties[propertyId];
                            const formatDate = property[0][1][0][1];
                            return new Date(formatDate.start_date);
                        }
                    }
                }
            }
            return getPageProperty(propertyName, block, post);
        },
        [post]
    );

    const link = useMemo(() => {
        return "/posts/" + getCanonicalPageId(post.id, post, { uuid: false });
    }, []);

    const image = useMemo(() => {
        return getProperty("Cover Image") ?? "";
    }, [getProperty]);

    const title = useMemo(() => {
        return getProperty("Article Title") ?? getPageTitle(post);
    }, [post]);

    const categories = useMemo(() => {
        const cates = getProperty("Categories").toString();
        if (!cates) return [];
        if (!cates.includes(",")) return [cates];
        return cates.split(",");
    }, [getProperty]);

    const timeToRead = useMemo(() => {
        const timeToRead = getProperty("Time to Read");
        return timeToRead ? timeToRead : t("common.words.few-minutes");
    }, [getProperty]);

    const excerpt = useMemo(() => {
        return getProperty("Excerpt");
    }, [getProperty]);

    const author = useMemo(() => {
        const authorName = getProperty("Author");
        const authorImage = getProperty("Author Image");
        const authorBio = getProperty("Author Bio");

        return {
            name: authorName ? authorName : "Polymerize Team",
            image: authorImage ? authorImage : "/favicon/favicon-32x32.png",
            bio: authorBio ? authorBio : "AI catalyst for material innovation.",
        };
    }, [getProperty]);

    const publishedDate = useMemo(() => {
        const date = getProperty("Published Date");
        return date &&
            Number(new Date()) - Number(new Date(date)) > 24 * 60 * 60 * 30
            ? new Date(date).toDateString()
            : t("common.words.recently-published");
    }, []);

    const featuredImage = useMemo(() => {
        const image = getProperty("Featured Image");
        return image ? image : "";
    }, []);

    return {
        link,
        image,
        title,
        categories,
        timeToRead,
        author,
        excerpt,
        publishedDate,
        featuredImage,
    };
};
