import React from 'react'
import { Icon } from 'types/components'

export const ApplicationsIcon: Icon = ({
  color,
  size = 24,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="13"
      // height="13"
      viewBox="0 0 16 17"
      fill="none"
      width={size}
      height={size}
      {...props}
    >
  <path d="M1.08182 0.7H6.49091C6.70178 0.7 6.87273 0.870946 6.87273 1.08182V6.49091C6.87273 6.70178 6.70178 6.87273 6.49091 6.87273H1.08182C0.870946 6.87273 0.7 6.70178 0.7 6.49091V1.08182C0.7 0.870946 0.870946 0.7 1.08182 0.7ZM9.37576 0.7H14.7848C14.9957 0.7 15.1667 0.870946 15.1667 1.08182V6.49091C15.1667 6.70178 14.9957 6.87273 14.7848 6.87273H9.37576C9.16489 6.87273 8.99394 6.70178 8.99394 6.49091V1.08182C8.99394 0.870946 9.16489 0.7 9.37576 0.7ZM1.08182 9.35455H6.49091C6.70178 9.35455 6.87273 9.52549 6.87273 9.73636V15.1455C6.87273 15.3563 6.70178 15.5273 6.49091 15.5273H1.08182C0.870946 15.5273 0.7 15.3563 0.7 15.1455V9.73636C0.7 9.52549 0.870946 9.35455 1.08182 9.35455ZM12.0803 15.5273C10.3758 15.5273 8.99394 14.1455 8.99394 12.4409C8.99394 10.7364 10.3758 9.35455 12.0803 9.35455C13.7849 9.35455 15.1667 10.7364 15.1667 12.4409C15.1667 14.1455 13.7849 15.5273 12.0803 15.5273Z" stroke="url(#paint0_linear_4400_37071)" stroke-width="1.4"/>
  <defs>
  <linearGradient id="paint0_linear_4400_37071" x1="0" y1="0" x2="17.7478" y2="2.41761" gradientUnits="userSpaceOnUse">
  <stop stop-color={color ?? "#1753E2"}/>
  <stop offset="1" stop-color={color ?? "#F39244"}/>
  </linearGradient>
  </defs>

    </svg>
  )
}
