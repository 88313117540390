import React, { ComponentPropsWithRef, forwardRef, ForwardRefExoticComponent } from "react"
import { mergeClasses } from 'utils'
import { PrimaryBtn } from 'components/library/components'

type P = ComponentPropsWithRef<'button'> & {
    ignoreClasses?: string
}

/**
 * **This is Primary Alternative Variant of Button Component**
 * 
 * @description
 * Used for call-to-actions, primary actions, user prompts and other really important things that usually targets the business needs.
 * 
 * @param ignoreClasses - classes that have to be ignored while merging with default classes
 * @param props - all the props of the button element
 * 
 * @returns Button Element
 */
export const PrimaryAlternativeBtn: ForwardRefExoticComponent<P> = forwardRef<HTMLButtonElement>((props: P, ref) => {
    const { children, className = '', ignoreClasses = '', ...restProps } = props

    return (
        <PrimaryBtn
            ref={ref}
            className={
                mergeClasses({
                    classes: ["bg-white text-theme-blue hover:bg-white ring-1 hover:ring-theme-blue", className]
                })
            }
            ignoreClasses={ignoreClasses}
            {...restProps}
        >
            {children}
        </PrimaryBtn>
    )
})

