import React from "react"

type P = {
    active: boolean
    onBtnClick: (pageNumber: number) => void
    value: number
}

export const PaginationButton = ({ active, onBtnClick, value }: P) => {
    return (
        <button
            aria-label={`select page number ${value}`}
            onClick={() => onBtnClick(value)}
            className={`${active ? 'bg-theme-blue text-white' : 'hover:bg-neutral-300'} rounded w-8 h-8 flex justify-center items-center shrink-0 cursor-pointer select-none`}
        >
            {value}
        </button>
    )
}
