import React, { ComponentPropsWithoutRef } from "react"
import { mergeClasses } from 'utils'

type P = ComponentPropsWithoutRef<'svg'>

export const NewsletterIllustration = ({ className = '', ...props }: P) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={mergeClasses({ classes: ['w-80', className] })}
            fill="none"
            viewBox="0 0 458 473"
            {...props}
        >
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M396.407 224.561c18.238 46.39 62.641 91.338 47.504 138.134-14.816 45.803-71.672 54.687-114.644 69.425-38.239 13.115-78.118 20.308-117.671 10.253-38.708-9.839-69.829-37.144-99.922-65.565C78.102 345.1 35.62 315.8 27.878 268.858c-7.81-47.359 22.992-89.194 44.455-130.595 21.227-40.943 36.262-97.04 78.972-107.478 43.255-10.57 79.099 38.217 119.804 58.902 28.527 14.497 60.42 19.972 82.785 44.047 22.889 24.638 29.944 58.858 42.513 90.827z"
                clipRule="evenodd"
                opacity="0.2"
            ></path>
            <path
                fill="url(#paint0_linear_4383_1831)"
                d="M345.05 140.767c-5.147-15.225-18.26-26.052-33.621-26.052s-28.475 10.814-33.621 26.039c-9.631 2.836-16.744 12.667-16.744 24.376 0 13.927 10.058 25.208 22.463 25.208h55.758c12.406 0 22.463-11.281 22.463-25.208 0-11.684-7.091-21.515-16.698-24.363z"
            ></path>
            <path
                fill="url(#paint1_linear_4383_1831)"
                d="M184.503 299.787c-8.081-23.84-28.672-40.792-52.791-40.792-24.12 0-44.71 16.933-52.791 40.772-15.121 4.441-26.29 19.834-26.29 38.168 0 21.807 15.791 39.47 35.27 39.47h87.55c19.479 0 35.271-17.663 35.271-39.47 0-18.294-11.134-33.688-26.219-38.148z"
            ></path>
            <rect
                width="199.108"
                height="130.992"
                x="140.158"
                y="175.099"
                fill="#FFBD88"
                rx="25.491"
            ></rect>
            <rect
                width="181.668"
                height="109.16"
                x="148.879"
                y="186.014"
                fill="#FCEADC"
                rx="16.994"
            ></rect>
            <path
                stroke="#A6BFF7"
                strokeLinecap="round"
                strokeWidth="2"
                d="M239.129 205.529h-74.52M224.308 213.4h-59.699M224.308 221.271h-59.699M201.869 229.143h-37.26M201.869 237.014h-37.26M201.869 244.884h-37.26"
            ></path>
            <path
                fill="#F39244"
                d="M202.757 306.09h73.5l7.819 26.744h-89.138l7.819-26.744z"
            ></path>
            <rect
                width="101.25"
                height="11.462"
                x="189.301"
                y="327.922"
                fill="#FFBD88"
                rx="5.731"
            ></rect>
            <path
                fill="#505F79"
                d="M356.057 274.805h-39.842c-1.509 0-2.957.675-4.025 1.878-1.067 1.202-1.667 2.833-1.667 4.534v76.95c0 1.7.6 3.331 1.667 4.534 1.068 1.203 2.516 1.878 4.025 1.878h39.842c1.509 0 2.957-.675 4.024-1.878 1.068-1.203 1.667-2.834 1.667-4.534v-76.95c0-1.701-.599-3.332-1.667-4.534-1.067-1.203-2.515-1.878-4.024-1.878zm-17.075 83.362h-5.692v-6.413h5.692v6.413zm-22.767-12.825v-64.125h39.842v64.125h-39.842z"
            ></path>
            <path fill="#E6F7FF" d="M315.18 280.343H357.091V345.925H315.18z"></path>
            <path
                fill="#F39244"
                d="M345.575 321.681h-.745v-11.893c0-4.936-3.239-9.019-7.452-9.697v-1.358c0-.773-.556-1.399-1.242-1.399-.686 0-1.242.626-1.242 1.399v1.358c-4.214.678-7.452 4.761-7.452 9.697v11.893h-.745c-.55 0-.994.501-.994 1.12v1.119c0 .154.112.28.249.28h6.706c0 2.162 1.559 3.918 3.478 3.918 1.919 0 3.477-1.756 3.477-3.918h6.707c.137 0 .248-.126.248-.28v-1.119c0-.619-.444-1.12-.993-1.12zm-9.439 4.198c-.823 0-1.491-.752-1.491-1.679h2.981c0 .927-.667 1.679-1.49 1.679z"
            ></path>
            <path
                fill="#FFBD88"
                d="M172.247 148.508c.328.369.513.867.513 1.389v37.923c0 1.088-.78 1.967-1.746 1.967h-34.932c-.966 0-1.746-.879-1.746-1.967v-51.162c0-1.088.78-1.968 1.746-1.968h23.181c.464 0 .911.21 1.239.578l11.745 13.24zm-3.515 2.293l-10.272-11.572v11.572h10.272z"
            ></path>
            <path
                fill="#FCEADC"
                d="M161.767 156.379a2.1 2.1 0 01.513 1.39v37.922c0 1.088-.781 1.968-1.747 1.968h-34.931c-.966 0-1.747-.88-1.747-1.968v-51.162c0-1.088.781-1.967 1.747-1.967h23.18c.464 0 .912.209 1.239.578l11.746 13.239zm-3.515 2.293L147.98 147.1v11.572h10.272z"
            ></path>
            <path
                fill="#E6F7FF"
                d="M235.257 250.955L211.02 232.11l-1.952-1.518 1.959 1.525v36.981h53.356v-36.981l-24.252 18.838a3.969 3.969 0 01-4.874 0zM263.553 227.009l2.788 3.583-1.958 1.525 1.965-1.525-2.788-3.583h-.007z"
            ></path>
            <path
                fill="#505F79"
                d="M267.221 224.256h-59.033a2.268 2.268 0 00-2.27 2.27v45.41a2.268 2.268 0 002.27 2.27h59.033a2.267 2.267 0 002.27-2.27v-45.41a2.267 2.267 0 00-2.27-2.27zm-6.705 5.116l-22.811 17.73-22.812-17.73h45.623zm3.867 2.745v36.981h-53.356v-36.981l-1.959-1.525 1.951 1.518 24.238 18.845a3.969 3.969 0 004.874 0l24.252-18.838 1.958-1.525-2.789-3.583h.008l2.788 3.583-1.965 1.525zM139.131 176.7a1.627 1.627 0 103.253 0 1.627 1.627 0 00-3.253 0zm.813-4.267h1.625a.205.205 0 00.204-.203v-11.787a.205.205 0 00-.204-.203h-1.625a.205.205 0 00-.204.203v11.787c0 .111.092.203.204.203z"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_4383_1831"
                    x1="311.406"
                    x2="311.406"
                    y1="114.715"
                    y2="190.338"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#305CBD"></stop>
                    <stop offset="0.599" stopColor="#3C67F0"></stop>
                    <stop offset="0.995" stopColor="#CCDBFB"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear_4383_1831"
                    x1="132.118"
                    x2="131.557"
                    y1="231.632"
                    y2="377.404"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#305CBD"></stop>
                    <stop offset="0.625" stopColor="#3C67F0"></stop>
                    <stop offset="1" stopColor="#CCDBFB"></stop>
                </linearGradient>
            </defs>
        </svg>
    )
}