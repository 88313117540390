import React from "react"
import { Icon } from "types/components"

export const NewsroomIcon: Icon = ({ color, size = 24, ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 22 22"
			width={size}
			{...props}
		>
        <path d="M0 2V17.8333C0 19.5697 1.42278 21 3.15 21H17.85C19.5772 21 21 19.5697 21 17.8333V6.22222H18.9V17.8333C18.9 18.4298 18.4433 18.8889 17.85 18.8889C17.2567 18.8889 16.8 18.4298 16.8 17.8333V2H0ZM2.1 4.11111H14.7V17.8333C14.7 18.2433 14.9929 18.5279 15.1368 18.8889H3.15C2.55672 18.8889 2.1 18.4298 2.1 17.8333V4.11111ZM4.2 6.22222V9.38889H12.6V6.22222H4.2ZM4.2 11.5V13.6111H12.6V11.5H4.2ZM4.2 15.7222V17.8333H12.6V15.7222H4.2Z" fill="url(#paint0_linear_4406_37095)"/>
        <defs>
        <linearGradient id="paint0_linear_4406_37095" x1="0" y1="2" x2="23.3715" y2="5.59876" gradientUnits="userSpaceOnUse">
        <stop stop-color={color ?? "#1753E2"}/>
        <stop offset="1" stop-color={color ?? "#F39244"}/>
        </linearGradient>
        </defs>
		</svg>
	)
}
