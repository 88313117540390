import React, { ChangeEvent, useRef } from "react"
import { Search } from 'react-feather'
import { useTranslation } from 'gatsby-plugin-react-i18next'

type P = {
    onSearch: (value: string) => void
}

export const SearchDataInput = ({ onSearch }: P) => {
    const timer = useRef<ReturnType<typeof setTimeout>>()
    const { t } = useTranslation()

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        if (timer.current) clearTimeout(timer.current)

        timer.current = setTimeout(() => {
            onSearch(e.target.value)
        }, 500)
    }

    return (
        <div className="relative w-full lg:w-auto">
            <Search className="absolute pointer-events-none text-gray-300 inset-4" />
            <input
                type="search"
                className="p-4 pl-14 w-full ring-1 ring-gray-300 rounded-md"
                placeholder={t('common.messages.searching-blog')}
                onChange={handleChange}
            />
        </div>
    )
}
