import React, { ComponentPropsWithoutRef } from "react"
import { mergeClasses } from 'utils'
import { getSiteBaseUrl } from "utils/urls"

type P = ComponentPropsWithoutRef<'a'>

export const FooterNavigationLink = ({ children, className = '', href, ...props }: P) => {
    return (
        <a
            href={`${getSiteBaseUrl(process.env.GATSBY_LANGUAGE_SELECTED)}${href}`}
            className={mergeClasses({ classes: ["hover:text-blue-700", className] })}
            {...props}
        >
            {children}
        </a>
    )
}
