import { getBlogUrl } from "utils/urls";

export const BLOG_BASE_URL = getBlogUrl(process.env.GATSBY_LANGUAGE_SELECTED);
export const LAB_BASE_URL = "https://lab.polymerize.io"
export const CONNECT_BASE_URL = "https://connect.polymerize.io"
export const CONNECT_CLOSES_IN = new Date("Nov 4, 2022 17:00:00")
export const POLYMERIZE_LINKEDIN_PROFILE = `${
  process.env.GATSBY_LANGUAGE_SELECTED === "ja"
    ? "https://www.linkedin.com/company/polymerize-japan/"
    : "https://www.linkedin.com/company/polymerize-io/"
}`;
export const POLYMERIZE_TWITTER_PROFILE = `${
  process.env.GATSBY_LANGUAGE_SELECTED === "ja"
    ? "https://twitter.com/Polymerize_jp"
    : "https://twitter.com/polymerize_io"
}`;
export const POLYMERIZE_FACEBOOK_PROFILE = `${
  process.env.GATSBY_LANGUAGE_SELECTED === "ja"
    ? "https://www.facebook.com/people/Polymerizejp/100084094925984/"
    : "https://www.facebook.com/people/Polymerizeio/100079234462948/"
}`;
export const POLYMERIZE_WECHAT_PROFILE = `http://weixin.qq.com/r/5hKbg9LETY8yrZbg90f0`;
