import React, { ComponentPropsWithoutRef } from "react"
import { Link } from 'gatsby'
import { useBlog } from 'utils/blogs'
import { Chip } from 'components/library/components'
import { ArrowRight } from 'react-feather'
import { mergeClasses } from 'utils'
import { toLowerTraincase } from 'utils/general'
import { useTranslation } from 'gatsby-plugin-react-i18next'

type P = ComponentPropsWithoutRef<'div'> & {
    post: any
    type: 'default' | 'no-image'
    imageClass?: string
}

export const BlogCard = ({ post, type, className = '', imageClass = '', ...props }: P) => {
    const { link, image, title, categories, timeToRead, excerpt, publishedDate } = useBlog(post)
    const { t } = useTranslation()

    return (
        <div className={ mergeClasses({ classes: ["w-full max-w-[384px] flex flex-col bg-white shadow-md rounded-md overflow-hidden", className] }) } { ...props }>
            { type === 'default' && (
                <Link to={ link }>
                    <img src={ image } className={ mergeClasses({ classes: ["shrink-0 object-cover h-auto sm:h-48 max-h-[350px] w-full", imageClass] }) } alt={ title } />
                </Link>
            ) }

            <div className="flex flex-col gap-7 p-6 grow">
                <p className="text-[#9FA8B7] flex gap-2 text-xs select-none">
                    <span className="block xl:inline">{ publishedDate }</span>
                    <span>·</span>
                    <span>{ timeToRead }</span>
                </p>

                <div className='flex flex-col gap-2 grow'>
                    <span className='flex gap-2 flex-wrap'>
                        { categories.map((category) => {
                            return (
                                <Link key={ category } to={ `/category/${toLowerTraincase(category)}` }>
                                    <Chip label={ category } />
                                </Link>
                            )
                        }) }
                    </span>
                    <Link to={ link }>
                        <p className="text-2xl font-semibold text-neutral-700">{ title }</p>
                    </Link>
                    <p className="text-neutral-500">{ excerpt }</p>
                </div>

                <div className="flex justify-between select-none">
                    <Link to={ link } className="flex font-semibold text-theme-orange items-center gap-2">
                        <span>
                            { t('common.cta.read-more') }
                        </span>
                        <ArrowRight size={ 16 } strokeWidth={ 3 } />
                    </Link>
                </div>
            </div>
        </div>
    )
}
