import { Link } from 'gatsby'
import React, { ComponentPropsWithoutRef } from "react"
import { ChevronRight } from 'react-feather'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { mergeClasses } from 'utils'
import { toLowerTraincaseInverse } from 'utils/general'
import { getSiteBaseUrl } from 'utils/urls'

type P = ({
    type: 'home'
} | {
    type: 'category'
    name: string
} | {
    type: 'blog'
    name: string
    path: string
}) & ComponentPropsWithoutRef<'div'>

export const Breadcrumbs = ({ className = '', ...props }: P) => {
    const { t } = useTranslation()

    return (
        <div className={mergeClasses({ classes: ['flex gap-1 items-center', className] })}>
            <a href={getSiteBaseUrl(process.env.GATSBY_LANGUAGE_SELECTED)}>
                {t('common.words.home')}
            </a>
            <ChevronRight size={16} className="shrink-0" />
            <a href={`${getSiteBaseUrl(process.env.GATSBY_LANGUAGE_SELECTED)}/support`}>
                {t('common.words.resources')}
            </a>
            <ChevronRight size={16} className="shrink-0" />
            <Link to='/' className={props.type === 'home' ? 'font-semibold' : ''}>
                {t('common.words.blog')}
            </Link>
            {props.type !== 'home' && <ChevronRight size={16} className="shrink-0" />}

            {props.type === 'category' && (
                <Link to={`/category/${props.name}`} className="font-semibold">
                    {props.name.includes('/') ? toLowerTraincaseInverse(props.name).toLocaleUpperCase() : toLowerTraincaseInverse(props.name)}
                </Link>
            )}

            {props.type === 'blog' && (
                <Link to={`/posts/${props.path}`} className="font-semibold text-ellipsis overflow-hidden whitespace-nowrap max-w-xs">
                    {toLowerTraincaseInverse(props.name)}
                </Link>
            )}
        </div>
    )
}
