import React from "react"
import { Icon } from "types/components"

export const RndOperationsIcon: Icon = ({ color, size = 24, ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 21 22"
			width={size}
			{...props}
		>
			<path
				stroke="url(#paint0_linear_6348_40061)"
				strokeLinecap="round"
				strokeMiterlimit="10"
				d="M1 20.583h19.167m-15.834-3.75a.417.417 0 00-.416-.416H2.25a.417.417 0 00-.417.416v3.75h2.5v-3.75zm5-4.166a.417.417 0 00-.416-.417H7.25a.417.417 0 00-.417.417v7.916h2.5v-7.916zm5 1.666a.417.417 0 00-.416-.416H12.25a.417.417 0 00-.417.416v6.25h2.5v-6.25zm5-5.833a.417.417 0 00-.416-.417H17.25a.417.417 0 00-.417.417v12.083h2.5V8.5z"
			></path>
			<path
				stroke="url(#paint1_linear_6348_40061)"
				strokeLinecap="round"
				strokeMiterlimit="10"
				d="M4.134 9.708l2.99-2.491m2.146-.405l2.628.875m5.185-4.27L13.951 7.07m-9.618 3.512a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zm5-4.166a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zm5 1.666a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zm5-5.416a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_6348_40061"
					x1="1"
					x2="21.884"
					y1="8.083"
					y2="12.544"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color ?? "#1753E2"}></stop>
					<stop offset="1" stopColor={color ?? "#F39244"}></stop>
				</linearGradient>
				<linearGradient
					id="paint1_linear_6348_40061"
					x1="1.833"
					x2="20.736"
					y1="1.417"
					y2="5.841"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color ?? "#1753E2"}></stop>
					<stop offset="1" stopColor={color ?? "#F39244"}></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
