import React, { Fragment, useState } from "react"
import { Popover, Transition } from "@headlessui/react"
import { Trans } from "gatsby-plugin-react-i18next"
import { ChevronDown, ChevronUp } from "react-feather"
import { TranslationKey } from "types/translation"
import { Link as LinkType } from "./header"
import { HeaderSubmenuItem } from "./header-submenu-item"

type P = {
	label: TranslationKey
	sublinks: LinkType[]
	isScrolled: boolean
}

export const Submenu = ({ label, sublinks }: P) => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<Popover className="relative">
			<Popover.Button
				className={`flex items-center gap-1 ${
					isOpen ? "font-semibold" : "font-normal"
				}`}
				onMouseEnter={() => setIsOpen(true)}
				onMouseLeave={() => setIsOpen(false)}
			>
				<Trans as="span" i18nKey={`${label}`} />
				{isOpen ? (
					<ChevronUp className="h-5 w-5" aria-hidden="true" />
				) : (
					<ChevronDown className="h-5 w-5" aria-hidden="true" />
				)}
			</Popover.Button>

			<Transition
				as={Fragment}
				show={isOpen}
				enter="transition ease-out duration-200"
				enterFrom="opacity-0 translate-y-1"
				enterTo="opacity-100 translate-y-0"
				leave="transition ease-in duration-150"
				leaveFrom="opacity-100 translate-y-0"
				leaveTo="opacity-0 translate-y-1"
			>
				<Popover.Panel
					className="absolute z-20 left-1/2 transform -translate-x-1/2 top-4 pt-6"
					onMouseEnter={() => setIsOpen(true)}
					onMouseLeave={() => setIsOpen(false)}
				>
					<ul className="flex flex-col gap-6 bg-white p-9 min-w-[350px] rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
						{sublinks.map((item) => {
							return (
								<HeaderSubmenuItem
									key={item.name}
									item={item}
									setIsOpen={setIsOpen}
								/>
							)
						})}
					</ul>
				</Popover.Panel>
			</Transition>
		</Popover>
	)
}
