import React from "react"
import { Icon } from "types/components"

export const MediaIcon: Icon = ({ color, size = 24, ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 22 18"
			width={size}
			{...props}
		>
			<path
				stroke="url(#paint0_linear_7001_41477)"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M21 3c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2m20 0v12c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3m20 0l-10 7L1 3"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_7001_41477"
					x1="1"
					x2="21"
					y1="9"
					y2="9"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color ?? "#1753E2"}></stop>
					<stop offset="1" stopColor={color ?? "#F39244"}></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
