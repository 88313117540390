import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import { LinkedIn } from "components/library/illustrations/LinkedIn";
import { Facebook } from "components/library/illustrations/Facebook";
import { Twitter } from "components/library/illustrations/Twitter";
import {
  POLYMERIZE_LINKEDIN_PROFILE,
  POLYMERIZE_TWITTER_PROFILE,
  POLYMERIZE_FACEBOOK_PROFILE,
  POLYMERIZE_WECHAT_PROFILE
} from "variables";
import { WeChat } from "components/library/illustrations/WeChat";

export const FooterHeader = () => {
  const { language } = useI18next();

  return (
    <div className="flex w-full flex-row gap-6 lg:gap-0 lg:justify-between lg:items-center">
      <Link to="/">
        <StaticImage
          placeholder="tracedSVG"
          className="w-44 lg:w-60"
          src="../../../images/Original-Black.png"
          alt="polymerize logo"
        />
      </Link>

      <div className="flex items-center gap-4 justify-end flex-grow-1">
        <a
          aria-label="Linkedin"
          href={POLYMERIZE_LINKEDIN_PROFILE}
          target="_blank"
          rel="noreferrer"
        >
          <LinkedIn className="fill-black hover:fill-blue-700 w-100 ease-in duration-150" />
        </a>
        {language === "zh" ? (
          <a
            aria-label="WeChat"
            href={POLYMERIZE_WECHAT_PROFILE}
            target="_blank"
            rel="noreferrer"
          >
            <WeChat className="fill-black hover:fill-blue-700 w-100 ease-in duration-150" />
          </a>
        ) : (
          <>
            <a
              aria-label="Twitter"
              href={POLYMERIZE_TWITTER_PROFILE}
              target="_blank"
              rel="noreferrer"
            >
              <Twitter className="fill-black hover:fill-blue-700 w-100 ease-in duration-150" />
            </a>
            <a
              aria-label="Facebook"
              href={POLYMERIZE_FACEBOOK_PROFILE}
              target="_blank"
              rel="noreferrer"
            >
              <Facebook className="fill-black hover:fill-blue-700 w-100 ease-in duration-150" />
            </a>
          </>
        )}
      </div>
    </div>
  );
};
