import React, { ComponentPropsWithoutRef } from "react"

type P = ComponentPropsWithoutRef<"svg"> & {
    size?: number
}

export const LanguageIcon = ({ size = 24, ...props }: P) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 32 32"
            width={size}
            height={size}
            {...props}
        >
            <path
                fill="currentColor"
                d="M27.85 29H30l-6-15h-2.35l-6 15h2.15l1.6-4h6.85l1.6 4zm-7.65-6l2.62-6.56L25.45 23H20.2zM18 7V5h-7V2H9v3H2v2h10.74a14.71 14.71 0 01-3.19 6.18A13.5 13.5 0 017.26 9h-2.1a16.47 16.47 0 003 5.58A16.838 16.838 0 013 18l.75 1.86A18.47 18.47 0 009.53 16a16.92 16.92 0 005.76 3.84L16 18a14.48 14.48 0 01-5.12-3.37A17.639 17.639 0 0014.8 7H18z"
            ></path>
        </svg>
    );
}
