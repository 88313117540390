import React from "react";
import { TranslationKey } from 'types/translation';
import { Trans } from 'gatsby-plugin-react-i18next'
import { SubItem } from './footer-navigation'
import { FooterNavigationLink as Link } from './footer-navigation-link';

type P = {
    title: TranslationKey
    subItems: SubItem[]
};

export const NavigationSection = ({ title, subItems }: P) => {
    return (
        <div className="flex flex-col gap-3">
            <h3 className="uppercase font-medium">
                <Trans i18nKey={title} />
            </h3>

            <ul className='flex flex-col gap-2'>
                {subItems.map(subItem => {
                    return (
                        <li key={subItem.name} className="transition duration-[300ms] hover:text-[#1B55CE]">
                            {!subItem.absolute ? (
                                <Link href={subItem.href} target="_blank">
                                    <Trans i18nKey={subItem.name} />
                                </Link>
                            ) : (
                                <a
                                    href={subItem.href}
                                    className="hover:text-blue-700"
                                >
                                    <Trans i18nKey={subItem.name} />
                                </a>
                            )}
                        </li>
                    )
                })}
            </ul>
        </div >
    )
};
