import { Popover, Transition } from '@headlessui/react'
import React, { useState } from "react"
import { ChevronDown } from 'react-feather'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { toLowerTraincaseInverse } from 'utils/general'

type P = {
    data: string[]
    onFilter: (newFilter: string[]) => void
    defaultVal?: string
    isDisabled?: boolean
}

export const FilterDataSelect = ({ data, onFilter, defaultVal, isDisabled = false }: P) => {
    const [filters, setFilters] = useState<string[]>(defaultVal ? [defaultVal] : [])
    const { t } = useTranslation()

    const onFilterChange = (checked: boolean, category: string) => {
        setFilters(state => {
            const newArr = checked ? state.concat(category) : state.filter(cat => cat !== category)
            onFilter(newArr)
            return newArr
        })
    }

    function handleClearAll() {
        setFilters([])
        onFilter([])
    }

    return (
        <Popover className="sm:relative w-full lg:w-auto">
            {({ open }) => (
                <>
                    <Popover.Button
                        className={`${(open || filters.length) ? 'text-theme-blue font-semibold' : 'text-neutral-500'} ring-1 ring-gray-300 px-5 py-4 rounded-md flex justify-between items-center text-base gap-8 bg-white w-full`}
                    >
                        <span>
                            {t('common.words.filter-by')}
                        </span>
                        <ChevronDown size={20} />
                    </Popover.Button>

                    <Transition
                        as={React.Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel
                            tabIndex={-1}
                            className="absolute z-10 w-[94vw] sm:max-w-xs bg-white p-7 sm:top-[120%] mt-3 transform sm:-translate-x-1/2 left-[3vw] sm:left-1/2 overflow-hidden rounded-lg shadow-lg ring-1 ring-black/10 flex flex-col">
                            <button className='text-right text-theme-blue select-none cursor-pointer disabled:opacity-40 disabled:cursor-not-allowed' disabled={isDisabled} onClick={handleClearAll}>
                                {t('common.words.clear-all')}
                            </button>

                            {data.map((item) => (
                                <span
                                    key={item}
                                    className={`flex items-center p-2 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 ${isDisabled ? 'cursor-not-allowed' : ''}`}
                                >
                                    <input
                                        id={item}
                                        disabled={isDisabled}
                                        checked={filters.includes(item)}
                                        onChange={(e) => onFilterChange(e.target.checked, item)}
                                        className="text-theme-blue w-4 h-4 disabled:cursor-not-allowed" type="checkbox"
                                    />
                                    <label htmlFor={item} className={`select-none cursor-pointer w-full shrink-0 inline-flex ml-2 pl-2 ${isDisabled ? 'cursor-not-allowed' : ''}`}>
                                        {
                                            item.includes('/') ? toLowerTraincaseInverse(item).toUpperCase() : toLowerTraincaseInverse(item)
                                        }
                                    </label>
                                </span>
                            ))}
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}
