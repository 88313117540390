import React from 'react'
import { Icon } from 'types/components'

export const PolymerizeLabsIcon: Icon = ({ color, size = 24, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="16"
      // height="18"
      viewBox="0 0 16 18"
      fill="none"
      width={size}
      height={size}
      {...props}
    >
      <path
        d="M3.75003 11.7L4.80003 8.99998L6.15003 9.59998L4.65003 12.15L3.75003 11.7Z"
        fill="url(#paint0_linear_11017_48020)"
      />
      <path
        d="M11.4 8.99998L8.70003 8.24998V7.34998L11.55 8.39998L11.4 8.99998Z"
        fill="url(#paint1_linear_11017_48020)"
      />
      <path
        d="M4.65005 5.24998L3.30005 2.99998L3.60005 2.39998L5.40005 4.79998L4.65005 5.24998Z"
        fill="url(#paint2_linear_11017_48020)"
      />
      <path
        d="M4.10004 1.65C4.10004 2.34036 3.5404 2.9 2.85004 2.9C2.15968 2.9 1.60004 2.34036 1.60004 1.65C1.60004 0.959644 2.15968 0.4 2.85004 0.4C3.5404 0.4 4.10004 0.959644 4.10004 1.65ZM8.69003 7.07999C8.69003 8.33368 7.67372 9.34999 6.42003 9.34999C5.16635 9.34999 4.15003 8.33368 4.15003 7.07999C4.15003 5.8263 5.16635 4.80999 6.42003 4.80999C7.67372 4.80999 8.69003 5.8263 8.69003 7.07999ZM14.9 9.10502C14.9 10.0688 14.1188 10.85 13.155 10.85C12.1913 10.85 11.41 10.0688 11.41 9.10502C11.41 8.14128 12.1913 7.36002 13.155 7.36002C14.1188 7.36002 14.9 8.14128 14.9 9.10502ZM6.05 14.775C6.05 16.3352 4.7852 17.6 3.225 17.6C1.6648 17.6 0.4 16.3352 0.4 14.775C0.4 13.2148 1.6648 11.95 3.225 11.95C4.7852 11.95 6.05 13.2148 6.05 14.775Z"
        stroke="url(#paint3_linear_11017_48020)"
        stroke-width="0.8"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11017_48020"
          x1="3.30005"
          y1="2.39998"
          x2="12.5706"
          y2="3.49281"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color ?? '#1753E2'}></stop>
          <stop offset="1" stopColor={color ?? '#F39244'}></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_11017_48020"
          x1="3.30005"
          y1="2.39998"
          x2="12.5706"
          y2="3.49281"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color ?? '#1753E2'}></stop>
          <stop offset="1" stopColor={color ?? '#F39244'}></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_11017_48020"
          x1="3.30005"
          y1="2.39998"
          x2="12.5706"
          y2="3.49281"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color ?? '#1753E2'}></stop>
          <stop offset="1" stopColor={color ?? '#F39244'}></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_11017_48020"
          x1="0"
          y1="0"
          x2="17.1905"
          y2="2.03568"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color ?? '#1753E2'}></stop>
          <stop offset="1" stopColor={color ?? '#F39244'}></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}
