import { Popover, Transition } from '@headlessui/react'
import React, { useState } from "react"
import { Check, ChevronDown } from 'react-feather'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { TranslationKey } from 'types/translation'

export type SortMethods = 'relevance' | 'alphabetical' | 'alphabetical-desc' | 'date-published' | 'date-published-desc'

const sortOptions: {
    name: TranslationKey
    key: SortMethods
}[] = [
        { name: 'common.words.relevance', key: 'relevance' },
        { name: 'common.words.alphabetical-ascending', key: 'alphabetical' },
        { name: 'common.words.alphabetical-descending', key: 'alphabetical-desc' },
        { name: 'common.words.date-published-earliest', key: 'date-published' },
        { name: 'common.words.date-published-latest', key: 'date-published-desc' },
    ]

type P = {
    onSort: (sortMethod: SortMethods) => void
}

export const SortDataSelect = ({ onSort }: P) => {
    const [sortMethod, setSortMethod] = useState<SortMethods>('relevance')
    const { t } = useTranslation()

    function handleSortChange(sortMethod: SortMethods) {
        setSortMethod(sortMethod)
        onSort(sortMethod)
    }

    return (
        <Popover className="sm:relative w-full lg:w-auto">
            {({ open }) => (
                <>
                    <Popover.Button
                        className={`${(open || sortMethod !== 'relevance') ? 'text-theme-blue font-semibold' : 'text-neutral-500'} ring-1 ring-gray-300 px-5 py-4 rounded-md justify-between flex items-center text-base gap-8 bg-white w-full`}
                    >
                        <span>
                            {t('common.words.sort-by')}
                        </span>
                        <ChevronDown size={20} />
                    </Popover.Button>

                    <Transition
                        as={React.Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel
                            tabIndex={-1}
                            className="absolute z-10 w-[94vw] sm:max-w-sm bg-white p-7 sm:top-[120%] mt-3 transform sm:-translate-x-1/2 left-[3vw] sm:left-1/2 overflow-hidden rounded-lg shadow-lg ring-1 ring-black/10 flex flex-col">
                            {sortOptions.map((opt) => (
                                <span
                                    key={opt.key}
                                    className={`flex items-center p-2 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 ${opt.key === sortMethod ? 'font-medium text-theme-blue' : ''}`}
                                    onClick={() => handleSortChange(opt.key)}
                                >
                                    <span className="select-none cursor-pointer grow">
                                        {t(opt.name) as string}
                                    </span>
                                    {opt.key === sortMethod && <Check size={16} />}
                                </span>
                            ))}
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}
