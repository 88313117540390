import React from "react"
import { Icon } from "types/components"

export const BookIcon: Icon = ({ color, size = 20, ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			fill="none"
			viewBox="0 0 18 22"
			{...props}
		>
			<path
				stroke="url(#paint0_linear_6993_41447)"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M1 18.5A2.5 2.5 0 013.5 16H17M1 18.5A2.5 2.5 0 003.5 21H17V1H3.5A2.5 2.5 0 001 3.5v15z"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_6993_41447"
					x1="1"
					x2="17"
					y1="11"
					y2="11"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color ?? "#1753E2"}></stop>
					<stop offset="1" stopColor={color ?? "#F39244"}></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
