import React, { ComponentPropsWithRef, forwardRef, memo, NamedExoticComponent } from "react"
import { mergeClasses } from 'utils'

type P = ComponentPropsWithRef<"input"> & {
	ignoreClasses?: string
	validator?: (val: string) => boolean
}

export const Input: NamedExoticComponent<P> = memo(
	forwardRef(({ className = "", ignoreClasses = "", validator, onChange, ...props }, ref) => {
		return (
			<input
				ref={ref}
				maxLength={255}
				onChange={(e) => {
					if (!validator && onChange) onChange(e)
					if (validator && validator(e.target.value) && onChange) onChange(e)
				}}
				className={mergeClasses({
					classes: [
						"rounded-md px-6 py-3 border border-gray-300 disabled:cursor-not-allowed disabled:opacity-50",
						className,
					],
					ignore: ignoreClasses,
				})}
				{...props}
			/>
		)
	}),
)

Input.displayName = "Default Input Component"
