import React, { Fragment, useState } from "react"
import { Popover, Transition } from "@headlessui/react"
import { ChevronDown, ChevronUp } from "react-feather"
import { useI18next } from "gatsby-plugin-react-i18next"
import { UsFlag } from "components/library/icons/flags/us-flag-icon";
import { JapanFlag } from "components/library/icons/flags/japan-flag-icon";
import { LanguageIcon } from "components/library/icons/language-icon";
import { ChinaFlag } from "components/library/icons/flags/china-flag-icon"
import { getBlogUrl } from "utils/urls";

export const LanguageSelector = () => {
	const { originalPath } = useI18next()
	const [isOpen, setIsOpen] = useState(false)

	return (
		<Popover className="relative">
			<Popover.Button
				onMouseEnter={() => setIsOpen(true)}
				onMouseLeave={() => setIsOpen(false)}
				className="flex text-theme-orange items-center font-medium sm:gap-2"
			>
				<LanguageIcon className="w-5 sm:w-6" />
				{isOpen ? (
					<ChevronUp className="h-5 w-5" aria-hidden="true" />
				) : (
					<ChevronDown className="h-5 w-5" aria-hidden="true" />
				)}
			</Popover.Button>

			<Transition
				as={Fragment}
				show={isOpen}
				enter="transition ease-out duration-200"
				enterFrom="opacity-0 translate-y-1"
				enterTo="opacity-100 translate-y-0"
				leave="transition ease-in duration-150"
				leaveFrom="opacity-100 translate-y-0"
				leaveTo="opacity-0 translate-y-1"
			>
				<Popover.Panel
					onMouseEnter={() => setIsOpen(true)}
					onMouseLeave={() => setIsOpen(false)}
					className="absolute right-0 sm:right-1/2 transform sm:translate-x-1/2 top-5 pt-6"
				>
					<div className="flex flex-col bg-white p-1 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 text-neutral-900">
						<div className="relative flex flex-col bg-white sm:p-4 gap-1">
							<a target="_blank" href={`${getBlogUrl("en")}${originalPath}`}>
								<Popover.Button className="p-4 flex gap-4 bg-gray-100 w-full items-center font-medium text-sm sm:text-base rounded">
									<UsFlag className="border border-gray-300" />
									English
								</Popover.Button>
							</a>
							<a target="_blank" href={`${getBlogUrl("ja")}${originalPath}`}>
								<Popover.Button className="p-4 flex gap-4 bg-gray-100 w-full items-center font-medium text-sm sm:text-base rounded">
									<JapanFlag className="border border-gray-300" />
									Japanese
								</Popover.Button>
							</a>

							<a
								target="_blank"
								href={`${getBlogUrl("zh")}${originalPath}`}
							>
								<Popover.Button
									className="p-4 flex gap-4 bg-gray-100 w-full items-center font-medium text-sm sm:text-base rounded"
								>
									<ChinaFlag className="border border-gray-300" />
									Mandarin
								</Popover.Button>
							</a>
						</div>
					</div>
				</Popover.Panel>
			</Transition>
		</Popover>
	)
}
