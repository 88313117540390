import React, { ComponentPropsWithoutRef } from "react"
import { ChevronLeft, ChevronRight } from 'react-feather'
import { PaginationButton } from './pagination-button'

type P = {
    pageCount: number
    onNext: () => void
    onBack: () => void
    onItemClick: (pageNumber: number) => void
    currentPage: number
} & ComponentPropsWithoutRef<'div'>



export const Pagination = ({ pageCount, onBack, onItemClick, onNext, currentPage }: P) => {
    return <div className="flex gap-3 items-center mx-auto py-12 text-neutral-500">
        <button
            disabled={currentPage === 1}
            onClick={() => onBack()}
            className="py-1 disabled:opacity-40 disabled:cursor-not-allowed"
            aria-label='previous page'
        >
            <ChevronLeft size={20} strokeWidth={3} />
        </button>


        {pageCount <= 6 &&
            <>
                {Array(pageCount).fill(0).map((_, i) => {
                    return <PaginationButton key={i} value={i + 1} onBtnClick={onItemClick} active={currentPage === i + 1} />
                })}
            </>
        }

        {pageCount > 6 &&
            <>
                <PaginationButton key={1} value={1} onBtnClick={onItemClick} active={currentPage === 1} />

                {currentPage - 2 > 1 && <span className='h-8 w-8 flex justify-center items-center'>...</span>}

                <>
                    {!(currentPage + 2 < pageCount) && currentPage - 2 > 1 && <PaginationButton
                        key={pageCount - 4}
                        active={false}
                        onBtnClick={onItemClick}
                        value={pageCount - 4}
                    />
                    }
                    {currentPage + 2 > pageCount + 1 &&
                        <PaginationButton
                            key={currentPage - 3}
                            active={false}
                            onBtnClick={onItemClick}
                            value={currentPage - 3}
                        />
                    }
                    {currentPage + 2 > pageCount &&
                        <PaginationButton
                            key={currentPage - 2}
                            active={false}
                            onBtnClick={onItemClick}
                            value={currentPage - 2}
                        />
                    }
                </>

                <>
                    {currentPage !== 1 && currentPage !== 2 && <PaginationButton
                        key={currentPage - 1}
                        active={false}
                        onBtnClick={onItemClick}
                        value={currentPage - 1}
                    />}
                    {currentPage !== 1 && currentPage !== pageCount && <PaginationButton
                        key={currentPage}
                        active={true}
                        onBtnClick={onItemClick}
                        value={currentPage}
                    />}
                    {currentPage !== pageCount && currentPage !== pageCount - 1 && <PaginationButton
                        key={currentPage + 1}
                        active={false}
                        onBtnClick={onItemClick}
                        value={currentPage + 1}
                    />}
                </>

                <>
                    {currentPage - 2 < 1 &&
                        <PaginationButton
                            key={currentPage + 2}
                            active={false}
                            onBtnClick={onItemClick}
                            value={currentPage + 2}
                        />
                    }
                    {currentPage - 2 < 0 &&
                        <PaginationButton
                            key={currentPage + 3}
                            active={false}
                            onBtnClick={onItemClick}
                            value={currentPage + 3}
                        />
                    }
                    {currentPage + 2 < pageCount && !(currentPage - 2 > 1) && <PaginationButton
                        key={5}
                        active={false}
                        onBtnClick={onItemClick}
                        value={5}
                    />
                    }
                </>

                {currentPage + 2 < pageCount && <span className='h-8 w-8 flex justify-center items-center'>...</span>}

                <PaginationButton key={pageCount} value={pageCount} onBtnClick={onItemClick} active={currentPage === pageCount} />
            </>
        }

        <button
            disabled={currentPage === pageCount}
            onClick={() => onNext()}
            className="py-1 disabled:opacity-40 disabled:cursor-not-allowed"
            aria-label='next page'
        >
            <ChevronRight size={20} strokeWidth={3} />
        </button>
    </div>
}
