import React from 'react'
import { Icon } from 'types/components'

export const AboutPolymerizeIcon: Icon = ({ color, size = 24, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="21"
      // height="21"
      viewBox="0 0 21 21"
      fill="none"
      width={size}
      height={size}
      {...props}
    >
      <path
        d="M10.5 0C4.71012 0 0 4.71012 0 10.5C0 16.2899 4.71012 21 10.5 21C16.2899 21 21 16.2899 21 10.5C21 4.71012 16.2899 0 10.5 0ZM7 18.5185V18.375C7 16.4447 8.56975 14.875 10.5 14.875C12.4303 14.875 14 16.4447 14 18.375V18.5185C12.9272 18.9884 11.7443 19.25 10.5 19.25C9.25575 19.25 8.07275 18.9884 7 18.5185ZM15.6844 17.5446C15.2854 15.043 13.1128 13.125 10.5 13.125C7.88725 13.125 5.7155 15.043 5.31562 17.5446C3.15437 15.9495 1.75 13.3857 1.75 10.5C1.75 5.67525 5.67525 1.75 10.5 1.75C15.3247 1.75 19.25 5.67525 19.25 10.5C19.25 13.3857 17.8456 15.9495 15.6844 17.5446ZM10.5 4.375C8.56975 4.375 7 5.94475 7 7.875C7 9.80525 8.56975 11.375 10.5 11.375C12.4303 11.375 14 9.80525 14 7.875C14 5.94475 12.4303 4.375 10.5 4.375ZM10.5 9.625C9.53487 9.625 8.75 8.84013 8.75 7.875C8.75 6.90987 9.53487 6.125 10.5 6.125C11.4651 6.125 12.25 6.90987 12.25 7.875C12.25 8.84013 11.4651 9.625 10.5 9.625Z"
        fill="url(#paint0_linear_10974_48030)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10974_48030"
          x1="0"
          y1="0"
          x2="23.4701"
          y2="3.26976"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={color ?? '#1753E2'} />
          <stop offset="1" stop-color={color ?? '#F39244'} />
        </linearGradient>
      </defs>
    </svg>
  )
}
