import React from "react"
import { Icon } from "types/components"

export const TechnologyScoutsIcon: Icon = ({ color, size = 24, ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 20 20"
			width={size}
			{...props}
		>
			<path
				fill="url(#paint0_linear_6348_40034)"
				d="M19.242 10.758A.777.777 0 0020 10a.777.777 0 00-.758-.758h-2.02V6.364h2.02A.777.777 0 0020 5.606a.777.777 0 00-.758-.758h-2.02v-2.07h-2.07V.758A.777.777 0 0014.393 0a.777.777 0 00-.758.758v2.02h-2.878V.758A.777.777 0 0010 0a.777.777 0 00-.758.758v2.02H6.364V.758A.777.777 0 005.606 0a.777.777 0 00-.758.758v2.02h-2.07v2.07H.758A.777.777 0 000 5.606c0 .404.354.758.758.758h2.02v2.878H.758A.777.777 0 000 10c0 .404.354.758.758.758h2.02v2.878H.758a.777.777 0 00-.758.758c0 .404.354.758.758.758h2.02v2.07h2.07v2.02c0 .404.354.758.758.758a.777.777 0 00.758-.758v-2.02h2.878v2.02c0 .404.354.758.758.758a.777.777 0 00.758-.758v-2.02h2.878v2.02c0 .404.354.758.758.758a.777.777 0 00.758-.758v-2.02h2.07v-2.07h2.02a.777.777 0 00.758-.758.777.777 0 00-.758-.758h-2.02v-2.878h2.02zm-3.686 4.798H4.444V4.444h11.112v11.112z"
			></path>
			<path
				fill="url(#paint1_linear_6348_40034)"
				d="M14.167 5.833H5.833v8.334h8.288V5.833h.046zm-1.948 6.421H7.781V7.791h4.438v4.463z"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_6348_40034"
					x1="0"
					x2="20"
					y1="10"
					y2="10"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color ?? "#1753E2"}></stop>
					<stop offset="1" stopColor={color ?? "#F39244"}></stop>
				</linearGradient>
				<linearGradient
					id="paint1_linear_6348_40034"
					x1="5.833"
					x2="14.167"
					y1="10"
					y2="10"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color ?? "#1753E2"}></stop>
					<stop offset="1" stopColor={color ?? "#F39244"}></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
