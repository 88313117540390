//@ts-nocheck
// REMOVING TYPESCRIPT CHECK BECAUSE IT THROWS ERROR WHEN USING SCRIPTS INDISE REACT HELMET
import React from "react"
import { Script } from "gatsby"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import { Tag } from 'types/tags'

type P = {
    children: React.ReactNode
    location: Location
}

type Query = {
    allTag: {
        nodes: Tag[]
    }
}

export const Seo = ({ children, location }: P) => {
    const { allTag: { nodes: tags } } = useStaticQuery<Query>(graphql`
    {
        allTag {
            nodes {
                id
                url
                title
                description
                image
            }
        }
    }
    `)

    const page = tags.find((tag) => {
        const pathName = location.pathname
        const pathNameLength = location.pathname.length
        if (pathName === '/') return tag.url === 'https://blog.polymerize.io'

        const path1 = pathName
        const path2 = pathName.endsWith('/') ? pathName.slice(0, pathNameLength - 1) : `${pathName}/`
        return tag.url.endsWith(path1) || tag.url.endsWith(path2)
    }) ?? {
        title: 'Blogs | Project Management | Data Analysis | R&D Operations',
        description: 'Check out our blog page to find the latest updates on industry news, AI/ML, project & data management, research, and development, etc.',
        image: 'https://blog.polymerize.io/images/blog-logo.jpg',
        url: location.href
    }

    const isSSR = typeof window === 'undefined'

    return (
        <>
            <Helmet

                htmlAttributes={{
                    lang: "en"
                }}
            >
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                {/* <!-- Primary Meta Tags --> */}
                <title>{page.title}</title>
                <meta name="title" content={page.title} />
                <meta name="description" content={page.description} />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                <meta name="author" content="Polymerize" />

                {/* <!--  Social tags --> */}
                {/* <meta name="keywords" content="materials science, polymers, informatics" /> */}

                {/* <!-- Schema.org markup for Google+ --> */}
                <meta itemProp="name" content={page.title} />
                <meta itemProp="description" content={page.description} />
                <meta itemProp="image" content={page.image} />

                {/* <!-- Open Graph data --> */}
                {/* <!-- <meta property="fb:app_id" content="214738555737136" /> --> */}
                <meta property="og:title" content={page.title} />
                <meta property="og:type" content="article" />
                <meta property="og:url" content={page.url} />
                <meta name='image' property="og:image" content={page.image} />
                <meta property="og:image:alt" content={page.title} />
                <meta property="og:description" content={page.description} />
                <meta property="og:site_name" content="Polymerize.io" />

                {/* <!-- Twitter Card data --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@polymerize_lab" />
                <meta name="twitter:title" content={page.title} />
                <meta name="twitter:description" content={page.description} />
                <meta name="twitter:creator" content="@polymerize_lab" />
                <meta name="twitter:image" content={page.image} />
                <meta name="twitter:image:alt" content={page.title} />

                {/* <!-- Favicon --> */}
                <link rel="apple-touch-icon" sizes="120x120" href="https://polymerize.io/favicon/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="https://polymerize.io/favicon/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="https://polymerize.io/favicon/favicon-16x16.png" />
                <link rel="manifest" href="https://polymerize.io/favicon/site.webmanifest" />
                <link rel="mask-icon" href="https://polymerize.io/favicon/safari-pinned-tab.svg" color="#ffffff" />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="theme-color" content="#ffffff" />
                <link href="https://assets.calendly.com/assets/external/widget.css" rel="preload" onLoad="this.rel='stylesheet'" />

                <Script strategy="off-main-thread" src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript"></Script>
                {/* GDPR */}
                <Script strategy="off-main-thread" type="text/javascript" charSet="UTF-8" src="//cdn.cookie-script.com/s/e0e97bd8beffbdaf2b89c6e200a686ef.js"></Script>

                {/* Global site tag (gtag.js) - Google Analytics */}
                {/* <script async src="https://www.googletagmanager.com/gtag/js?id=G-X0P59YMCB3"></script> */}
                {/* <script>
                    {(function () {
                        window.dataLayer = window.dataLayer || [];
                        function gtag() { window.dataLayer.push(arguments); }
                        gtag('js', new Date());

                        gtag('config', 'G-X0P59YMCB3');

                        function sendToGoogleAnalytics({ name, delta, value, id }) {
                            // Assumes the global `gtag()` function exists, see:
                            // https://developers.google.com/analytics/devguides/collection/ga4
                            gtag('event', name, {
                                // Built-in params:
                                value: delta, // Use `delta` so the value can be summed.
                                // Custom params:
                                metric_id: id, // Needed to aggregate events.
                                metric_value: value, // Optional.
                                metric_delta: delta, // Optional.

                                // OPTIONAL: any additional params or debug info here.
                                // See: https://web.dev/debug-web-vitals-in-the-field/
                                // metric_rating: 'good' | 'ni' | 'poor',
                                // debug_info: '...',
                                // ...
                            });
                        }

                        getCLS(sendToGoogleAnalytics);
                        getFID(sendToGoogleAnalytics);
                        getLCP(sendToGoogleAnalytics);
                    })()}
                </script> */}


                <Script type="text/javascript">
                    {(() => {
                        if (!isSSR) {
                            (function (l) {
                                let _linkedin_partner_id = "4175169"
                                window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []
                                window._linkedin_data_partner_ids.push(_linkedin_partner_id)
                                if (!l) {
                                    window.lintrk = function (a, b) { window.lintrk.q.push([a, b]) };
                                    window.lintrk.q = []
                                }
                                var s = document.getElementsByTagName("script")[0]
                                var b = document.createElement("script");
                                b.type = "text/javascript"; b.async = true;
                                b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                                s.parentNode.insertBefore(b, s);
                            })(window.lintrk)
                        }
                    })()}
                </Script>
                <Script type="text/javascript">
                    {(() => {
                        if (!isSSR) {
                            (function (c, l, a, r, i, t, y) {
                                c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
                                t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
                                y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
                            })(window, document, "clarity", "script", "agpt2o2eyk")
                        }
                    })()}
                </Script>
                <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/8531037.js" />

                {/* GOOGLE TAG MANAGER */}
                <Script
                    src="https://www.googletagmanager.com/gtag/js?id=G-X0P59YMCB3"
                    forward={[`dataLayer.push`]}
                />
                <Script id="gtm-init">
                    {(function () {
                        if (!isSSR) {
                            window.dataLayer = window.dataLayer || [];
                            window.gtag = function () { window.dataLayer.push(arguments); }
                            window.gtag('js', new Date());

                            window.gtag('config', 'G-X0P59YMCB3');
                            window.gtag('config', 'UA-228784423-1');

                            (function (w, d, s, l, i) {
                                w[l] = w[l] || []; w[l].push({
                                    'gtm.start':
                                        new Date().getTime(), event: 'gtm.js'
                                }); var f = d.getElementsByTagName(s)[0],
                                    j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                                        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
                            })(window, document, 'script', 'dataLayer', 'GTM-KJCV59W');
                        }
                    })()}
                </Script>
                <Script>
                    {(() => {
                        if (!isSSR) {
                            window.requestADemo = function () {
                                window.gtag('event', 'conversion', { 'send_to': 'AW-10853993658/uA0tCPvxlrsDELqZy7co' });

                                window.Calendly.initPopupWidget({
                                    url: "https://calendly.com/polymerize/product-demo?primary_color=ff8100&hide_gdpr_banner=1",
                                    // url: "https://calendly.com/polymerize/product-demo-jp?primary_color=ff8100&hide_gdpr_banner=1",
                                })
                            }
                        }
                    })()}
                </Script>
            </Helmet>

            <main>{children}</main>
        </>
    )
}
