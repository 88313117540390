import React from "react";
import { Illustration } from "types/components";
import { mergeClasses } from "utils";

export const Twitter: Illustration = ({ className = "", ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={mergeClasses({ classes: ["w-20", className] })}
      {...props}
    >
      <g clip-path="url(#clip0_4452_37280)">
        <path
          d="M22.2224 23.5853H1.90886C1.12967 23.5853 0.498047 22.9517 0.498047 22.1702V1.79568C0.498047 1.01414 1.12967 0.380615 1.90886 0.380615H22.2221C23.0013 0.380615 23.6329 1.01414 23.6329 1.79568V22.1702C23.6332 22.9517 23.0016 23.5853 22.2224 23.5853Z"
          stroke="white"
          stroke-width="0.0282381"
          stroke-miterlimit="10"
        />
        <path
          d="M4.04993 4.42337L10.268 12.7625L4.01074 19.5426H5.41912L10.8975 13.6064L15.3237 19.5426H20.1161L13.548 10.7342L19.3724 4.4231H17.964L12.9189 9.89035L8.84236 4.42337H4.04993ZM6.12101 5.46379H8.32263L18.0448 18.5022H15.8432L6.12101 5.46379Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4452_37280">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
