import React from "react"
import { SearchDataInput } from './search-data-input'
import { FilterDataSelect } from './filter-data-select'
import { SortDataSelect, SortMethods } from './sort-data-select'
import { useTranslation } from 'gatsby-plugin-react-i18next'

type P = {
    type: 'home' | 'category'
    onSearch: (value: string) => void
    onFilter: (newFilters: string[]) => void
    onSort: (sortMethod: SortMethods) => void
    categories: string[]
}

export const FilterData = ({ onSearch, categories, onSort, onFilter }: P) => {
    const { t } = useTranslation()

    return (
        <div className='flex flex-col gap-12 py-16'>
            <h2 className="text-2xl text-center text-neutral-500">
                {t('common.messages.looking-for-something-specific')}
            </h2>

            <div className="flex flex-col lg:flex-row w-full lg:justify-center gap-4">
                <SearchDataInput onSearch={onSearch} />

                <div className='flex gap-4'>
                    <FilterDataSelect data={categories} onFilter={onFilter} />
                    <SortDataSelect onSort={onSort} />
                </div>
            </div>
        </div >
    )
}
