import React, { Dispatch, SetStateAction, useState } from "react"
import { Link, Trans, useI18next } from "gatsby-plugin-react-i18next"
import { Link as LinkType } from "./header"
import { getSiteBaseUrl } from "utils/urls"

type P = {
	item: LinkType
	setIsOpen: Dispatch<SetStateAction<boolean>>
}

export const HeaderSubmenuItem = ({ item, setIsOpen }: P) => {
	const Icon = item.icon

	const [isHovered, setIsHovered] = useState(false)
	const { language } = useI18next()

	console.log({ isHovered })

	return (
    <li
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {item.newWindow ? (
        <a
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => setIsOpen(false)}
          href={item.href}
          className={`font-medium flex gap-4 items-center transition duration-[400ms] origin-left hover:scale-[1.03] hover:font-semibold ${
            isHovered ? "text-theme-blue" : "text-gray-900"
          }`}
        >
          <Icon
            color={isHovered ? "#1753e2" : undefined}
            className="text-gray-400"
          />
          <Trans i18nKey={`${item.name}`} />
        </a>
      ) : (
        <Link
          onClick={() => setIsOpen(false)}
          to={`${getSiteBaseUrl(language)}${item.href}`}
          className={`font-medium flex gap-4 items-center transition duration-[400ms] origin-left hover:scale-[1.03] hover:font-semibold ${
            isHovered ? "text-theme-blue" : "text-gray-900"
          }`}
        >
          <Icon
            color={isHovered ? "#1753e2" : undefined}
            className="text-gray-400"
          />
          <Trans i18nKey={`${item.name}`} />
        </Link>
      )}
    </li>
  );
}
