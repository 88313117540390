import React, { useMemo } from "react"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { getSiteBaseUrl } from "utils/urls"

export const FooterCopyright = () => {
    const { t } = useTranslation()
    const year = useMemo(() => new Date().getFullYear(), [])

    return (
        <div className="text-xs border-t border-t-neutral-400 text-neutral-400 flex flex-col gap-2 lg:gap-0 lg:flex-row lg:justify-between pt-5">
            <p>
                {t('common.words.copyright')} &copy; {year} Polymerize. {t('common.words.all-rights-reserved')}.
            </p>
            <p className='flex gap-1 items-center'>
                <a href={`${getSiteBaseUrl(process.env.GATSBY_LANGUAGE_SELECTED)}/terms-of-use`}>
                    {t('common.words.terms-of-use')}
                </a>
                <span>&</span>
                <a href={`${getSiteBaseUrl(process.env.GATSBY_LANGUAGE_SELECTED)}/privacy-policy`}>
                    {t('common.words.privacy-policy')}
                </a></p>
        </div>
    )
}