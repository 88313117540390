import React from "react"
import { toLowerTraincase, toLowerTraincaseInverse } from 'utils/general'

type P = {
    categories: string[]
}

export const CategoriesBar = ({ categories }: P) => {
    return <div className='flex justify-center flex-wrap gap-3'>
        {categories.map(category => {
            const categ = toLowerTraincase(category)

            return (
                <a
                    key={categ}
                    href={`category/${categ}`}
                    className='px-4 select-none cursor-pointer py-2 bg-[#EBECF0] text-neutral-600 font-semibold rounded-sm'
                >
                    {
                        category.includes('/') ? toLowerTraincaseInverse(category).toUpperCase() : toLowerTraincaseInverse(category)
                    }
                </a>
            )
        })}
    </div>
}
