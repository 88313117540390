import { SortMethods } from "components/core/filter-data/sort-data-select"
import { getPageTitle } from "notion-utils"
import { useMemo, useState } from "react"
import { toLowerTraincase } from "utils/general"
import { getProperty } from "utils/notion"

export const useBlogsManipulation = (blogs: any[]) => {
	const [query, setQuery] = useState("")
	const [filters, setFilters] = useState<string[]>([])
	const [sortMethod, setSortMethod] = useState<SortMethods>("relevance")

	const data = useMemo(() => {
		if (!query && filters.length === 0 && sortMethod === "relevance")
			return null

		let newData = [...blogs]

		if (!!query) {
			newData = newData.filter((post) => {
				const q = query.toLowerCase()
				const title = getPageTitle(post).toLowerCase()
				const excerpt = getProperty("Excerpt", post).toString()?.toLowerCase() ?? ""
				const category = getProperty("Categories", post).toString()?.toLowerCase() ?? ""
				return title.includes(q) || excerpt.includes(q) || category.includes(q)
			})
		}

		if (filters.length > 0) {
			newData = newData.filter((article) => {
				const category = toLowerTraincase(
					getProperty("Categories", article).toString() ?? ""
				)
				return filters.includes(category)
			})
			console.log(newData)
		}

		if (sortMethod === "alphabetical") {
			newData.sort((a, b) => {
				const aTitle = getPageTitle(a)
				const bTitle = getPageTitle(b)
				return aTitle > bTitle ? 1 : -1
			})
		}
		if (sortMethod === "alphabetical-desc") {
			newData.sort((a, b) => {
				const aTitle = getPageTitle(a)
				const bTitle = getPageTitle(b)
				return aTitle > bTitle ? -1 : 1
			})
		}
		if (sortMethod === "date-published-desc") {
			newData.sort((a, b) => {
				const aDate = new Date(getProperty("Published Date", a).toString() ?? "").getTime()
				const bDate = new Date(getProperty("Published Date", b).toString() ?? "").getTime()
				return aDate > bDate ? -1 : 1
			})
		}
		if (sortMethod === "date-published") {
			newData.sort((a, b) => {
				const aDate = new Date(getProperty("Published Date", a).toString() ?? "").getTime()
				const bDate = new Date(getProperty("Published Date", b).toString() ?? "").getTime()
				return aDate > bDate ? 1 : -1
			})
		}

		return newData
	}, [query, filters, sortMethod])

	return { setFilters, setQuery, setSortMethod, data, query, filters }
}
