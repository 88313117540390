import React from "react"
import { Icon } from "types/components"

export const BlogIcon: Icon = ({ color, size = 24, ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			fill="none"
			viewBox="0 0 22 20"
			{...props}
		>
			<path
				stroke="url(#paint0_linear_6953_41601)"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M11 5a4 4 0 00-4-4H1v15h7a3 3 0 013 3m0-14v14m0-14a4 4 0 014-4h6v15h-7a3 3 0 00-3 3"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_6953_41601"
					x1="1"
					x2="23.253"
					y1="1"
					y2="4.445"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color ?? "#1753E2"}></stop>
					<stop offset="1" stopColor={color ?? "#F39244"}></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
