import React from "react"
import { Icon } from "types/components"

export const ContactIcon: Icon = ({ color, size = 24, ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 20 20"
			width={size}
			{...props}
		>
			<path
				stroke="url(#paint0_linear_6953_41462)"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M19 9.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L1 19l1.9-5.7A8.38 8.38 0 012 9.5a8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_6953_41462"
					x1="1"
					x2="21.117"
					y1="1"
					y2="3.803"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color ?? "#1753E2"}></stop>
					<stop offset="1" stopColor={color ?? "#F39244"}></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
