import React from 'react'
import { Icon } from 'types/components'

export const EventsIcon: Icon = ({ color, size = 24, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="17"
      // height="17"
      viewBox="0 0 17 17"
      fill="none"
      width={size}
      height={size}
      {...props}
    >
      <g clip-path="url(#clip0_10972_48015)">
        <path
          d="M14.875 1.41667H12.75V0H11.3333V1.41667H5.66667V0H4.25V1.41667H2.125C1.56141 1.41667 1.02091 1.64055 0.622398 2.03906C0.223883 2.43758 0 2.97808 0 3.54167L0 17H17V3.54167C17 2.97808 16.7761 2.43758 16.3776 2.03906C15.9791 1.64055 15.4386 1.41667 14.875 1.41667ZM1.41667 3.54167C1.41667 3.3538 1.49129 3.17364 1.62413 3.0408C1.75697 2.90796 1.93714 2.83333 2.125 2.83333H14.875C15.0629 2.83333 15.243 2.90796 15.3759 3.0408C15.5087 3.17364 15.5833 3.3538 15.5833 3.54167V5.66667H1.41667V3.54167ZM1.41667 15.5833V7.08333H15.5833V15.5833H1.41667Z"
          fill="url(#paint0_linear_10972_48015)"
        />
        <path
          d="M12.0417 9.20833H10.625V10.625H12.0417V9.20833Z"
          fill="url(#paint1_linear_10972_48015)"
        />
        <path
          d="M9.20829 9.20833H7.79163V10.625H9.20829V9.20833Z"
          fill="url(#paint2_linear_10972_48015)"
        />
        <path
          d="M6.37504 9.20833H4.95837V10.625H6.37504V9.20833Z"
          fill="url(#paint3_linear_10972_48015)"
        />
        <path
          d="M12.0417 12.0417H10.625V13.4583H12.0417V12.0417Z"
          fill="url(#paint4_linear_10972_48015)"
        />
        <path
          d="M9.20829 12.0417H7.79163V13.4583H9.20829V12.0417Z"
          fill="url(#paint5_linear_10972_48015)"
        />
        <path
          d="M6.37504 12.0417H4.95837V13.4583H6.37504V12.0417Z"
          fill="url(#paint6_linear_10972_48015)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_10972_48015"
          x1="0"
          y1="0"
          x2="18.9996"
          y2="2.64695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={color ?? '#1753E2'} />
          <stop offset="1" stop-color={color ?? '#F39244'} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_10972_48015"
          x1="10.625"
          y1="9.20833"
          x2="12.2083"
          y2="9.42891"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={color ?? '#1753E2'} />
          <stop offset="1" stop-color={color ?? '#F39244'} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_10972_48015"
          x1="7.79163"
          y1="9.20833"
          x2="9.37493"
          y2="9.42891"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={color ?? '#1753E2'} />
          <stop offset="1" stop-color={color ?? '#F39244'} />
        </linearGradient>
        <linearGradient
          id="paint3_linear_10972_48015"
          x1="4.95837"
          y1="9.20833"
          x2="6.54167"
          y2="9.42891"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={color ?? '#1753E2'} />
          <stop offset="1" stop-color={color ?? '#F39244'} />
        </linearGradient>
        <linearGradient
          id="paint4_linear_10972_48015"
          x1="10.625"
          y1="12.0417"
          x2="12.2083"
          y2="12.2623"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={color ?? '#1753E2'} />
          <stop offset="1" stop-color={color ?? '#F39244'} />
        </linearGradient>
        <linearGradient
          id="paint5_linear_10972_48015"
          x1="7.79163"
          y1="12.0417"
          x2="9.37493"
          y2="12.2623"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={color ?? '#1753E2'} />
          <stop offset="1" stop-color={color ?? '#F39244'} />
        </linearGradient>
        <linearGradient
          id="paint6_linear_10972_48015"
          x1="4.95837"
          y1="12.0417"
          x2="6.54167"
          y2="12.2623"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={color ?? '#1753E2'} />
          <stop offset="1" stop-color={color ?? '#F39244'} />
        </linearGradient>
        <clipPath id="clip0_10972_48015">
          <rect width="17" height="17" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
