import React from "react"
import { Footer, Header } from "components/library/components"
import { Subheader } from 'components/core'
import { Intercom } from "react-live-chat-loader";

export default function Layout({ children, location, ...props }) {
    const isSSR = typeof window === 'undefined'
    if (isSSR) return null

    return (
        <div className="flex flex-col min-h-full relative">
            <noscript>
                <iframe
                    title='google tag manager'
                    src="https://www.googletagmanager.com/ns.html?id=GTM-KJCV59W"
                    height={0}
                    width={0}
                    style={{ display: 'none', visibility: 'hidden' }}>
                </iframe>
            </noscript>
            {/* <AnnouncementBar /> */}
            <Header />
            <Subheader
                currentCategory={props.pageContext.category ?? ''}
                categories={props.pageContext.categories}
            />
            <div className="grow">{children}</div>
            <Footer />
            <Intercom />
        </div >
    )
}
