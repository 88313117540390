import React from 'react'
import { Icon } from 'types/components'

export const SolutionsIcon: Icon = ({
  color,
  size = 24,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="13"
      // height="13"
      viewBox="0 0 24 24"
      fill="none"
      width={size}
      height={size}
      {...props}
    >
    <g clip-path="url(#clip0_4252_1207)">
    <path d="M15.2139 22.0714H8.7853C8.66744 22.0714 8.57101 22.1679 8.57101 22.2857V23.1429C8.57101 23.617 8.95405 24 9.42815 24H14.571C15.0451 24 15.4282 23.617 15.4282 23.1429V22.2857C15.4282 22.1679 15.3317 22.0714 15.2139 22.0714ZM11.9996 0C7.14869 0 3.21387 3.93482 3.21387 8.78571C3.21387 12.0375 4.98172 14.8768 7.60672 16.3955V19.5C7.60672 19.9741 7.98976 20.3571 8.46387 20.3571H15.5353C16.0094 20.3571 16.3924 19.9741 16.3924 19.5V16.3955C19.0174 14.8768 20.7853 12.0375 20.7853 8.78571C20.7853 3.93482 16.8505 0 11.9996 0ZM15.4255 14.7268L14.4639 15.2839V18.4286H9.5353V15.2839L8.57369 14.7268C6.46565 13.508 5.14244 11.2634 5.14244 8.78571C5.14244 4.99821 8.21208 1.92857 11.9996 1.92857C15.7871 1.92857 18.8567 4.99821 18.8567 8.78571C18.8567 11.2634 17.5335 13.508 15.4255 14.7268Z" fill="url(#paint0_linear_4252_1207)"/>
    </g>
    <defs>
    <linearGradient id="paint0_linear_4252_1207" x1="3.21387" y1="0" x2="23.0271" y2="2.02094" gradientUnits="userSpaceOnUse">
    <stop stop-color={color ?? "#1753E2"}/>
    <stop offset="1" stop-color={color ?? "#F39244"}/>
    </linearGradient>
    <clipPath id="clip0_4252_1207">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>

    </svg>
  )
}
