export const getSiteBaseUrl = (lang: string | undefined) => {
    switch (lang) {
        case 'en':
            return "https://polymerize.io"
        case 'ja':
            return "https://polymerize.jp"
        case 'zh':
            return "https://polymerize.tech"

        default:
            return "https://polymerize.io"
    }
}

export const getBlogUrl = (lang: string | undefined) => {
    switch (lang) {
      case 'en':
        return 'https://blog.polymerize.io'
      case 'ja':
        return 'https://blog.polymerize.jp'
      case 'zh':
        return 'https://blog.polymerize.tech'
  
      default:
        return 'https://blog.polymerize.io'
    }
  }
  