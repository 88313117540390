import React, { FormEvent, useState } from "react"
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import { TranslationKey } from 'types/translation'

const API_URL = `${process.env.GATSBY_BACKEND_API_URL_V1}/subscribe_to_newsletter`

export const FooterNewsletter = () => {
    const [email, setEmail] = useState('')
    const [submitting, setSubmitting] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState<TranslationKey | null>(null)
    const { t } = useTranslation()

    async function handleNewsletterSubscription(e: FormEvent) {
        e.preventDefault()
        try {
            setSubmitting(true)
            const response = await fetch(API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            })
            const data: { result: { status: 'Failed' | 'Success', message: string } } = await response.json()

            if (data.result.status === 'Failed') return setError('newsletter.footer.error')
            setSuccess(true)
        } catch (err) {
            console.log({ err })
            setError('common.messages.internal-server-error')
        } finally {
            setEmail('')
            setSubmitting(false)
        }
    }

    return (
        <div className="col-span-2 flex flex-col gap-6 lg:gap-10 lg:w-80">
            <p className='font-semibold lg:text-right text-lg max-w-[320px]'>
                {t('newsletter.footer.headline')}
            </p>

            <p className='lg:text-right'>
                {t('newsletter.footer.about')}
            </p>

            {success && !error && (
                <div className='w-full lg:text-right'>
                    {t('newsletter.footer.success')}
                </div>
            )}
            {error && (
                <div className='w-full lg:text-right text-red-400'>
                    <Trans i18nKey={error} />
                </div>
            )}

            {!success && !error && (
                <form onSubmit={handleNewsletterSubscription} className='flex flex-col gap-4 lg:gap-0 lg:flex-row w-full'>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={t('common.messages.enter-your-email')}
                        className='px-4 py-4 border border-neutral-400 lg:border-r-0 rounded-md lg:rounded-r-none w-full focus:outline-none'
                        required
                    />
                    <button
                        disabled={submitting}
                        type="submit"
                        className='bg-blue-700 font-semibold rounded-md lg:rounded-l-none text-white px-8 py-4 lg:py-2 shrink-0 disabled:opacity-40 disabled:cursor-not-allowed'
                    >
                        {submitting ? `${t('common.cta.submitting')}...` : t('common.cta.signup')}
                    </button>
                </form>
            )}
        </div>
    )
}
