import React from "react"
import { Disclosure, Popover, Transition } from '@headlessui/react'
import { Link, Trans, useI18next } from 'gatsby-plugin-react-i18next'
import { Minus, Plus } from 'react-feather'
import { Link as LinkType } from './header'
import { TranslationKey } from 'types/translation'
import { getSiteBaseUrl } from "utils/urls"

type P = {
    label: TranslationKey
    sublinks: LinkType[]
}

export const MobileSubmenu = ({ label, sublinks }: P) => {
    const { language } = useI18next()

    return (
        <Disclosure as="div" className="border-b border-gray-200">
            {({ open }) => (
                <>
                    <Disclosure.Button className={`hover:text-gray-900 hover:bg-gray-50 py-3 px-6 w-full flex items-center justify-between ${open ? 'text-blue-700' : 'text-gray-400'}`}>
                        <span className={open ? 'text-blue-700' : 'text-gray-900'}>
                            <Trans i18nKey={label} />
                        </span>
                        <span>{open ? <Minus /> : <Plus />}</span>
                    </Disclosure.Button>

                    <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                    >
                        <Disclosure.Panel className="pt-3 pb-7 px-6 text-sm flex flex-col gap-4">
                            {sublinks.map(item => {
                                return item.newWindow ? (
                                    <Popover.Button
                                        as="a"
                                        key={item.name}
                                        href={item.href}
                                    >
                                        <Trans i18nKey={item.name} />
                                    </Popover.Button>
                                ) : (
                                    <Popover.Button
                                        as={Link}
                                        key={item.href}
                                        to={`${getSiteBaseUrl(language)}${item.href}`}
                                    >
                                        <Trans i18nKey={item.name} />
                                    </Popover.Button>
                                )
                            })}
                        </Disclosure.Panel>
                    </Transition>
                </>
            )}
        </Disclosure>
    )
}
