import React, { ComponentPropsWithRef, forwardRef, ForwardRefExoticComponent } from "react"
import { mergeClasses } from 'utils'

type P = ComponentPropsWithRef<'button'> & {
    ignoreClasses?: string
}

/**
 * **This is Primary Variant of Button Component**
 * 
 * @description
 * Used for call-to-actions, primary actions, user prompts and other really important things that usually targets the business needs.
 * 
 * @param ignoreClasses - classes that have to be ignored while merging with default classes
 * @param props - all the props of the button element
 * 
 * @returns Button Element
 */
export const PrimaryBtn: ForwardRefExoticComponent<P> = forwardRef<HTMLButtonElement>((props: P, ref) => {
    const { children, className = '', ignoreClasses = '', ...restProps } = props

    return <button ref={ref} className={mergeClasses({
        classes: ['px-4 py-3 hover:bg-theme-blue-hover leading-tight font-bold rounded-[4px] bg-theme-blue text-white', className],
        ignore: ignoreClasses
    })} {...restProps}>
        {children}
    </button>
})

