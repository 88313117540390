import React, { Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import LogoBlack from "images/Original-Black.png"
import { X } from "react-feather"
import { Link as LinkType } from "."
import { MobileSubmenu } from "./header-mobile-submenu"

type P = {
	products: LinkType[]
	solutions: LinkType[]
	resources: LinkType[]
	company: LinkType[]
}

export const MobileMenu = ({ products, solutions, company, resources }: P) => {
	const { t } = useTranslation()

	return (
		<Transition
			as={Fragment}
			enter="duration-150 ease-out"
			enterFrom="opacity-0 scale-95"
			enterTo="opacity-100 scale-100"
			leave="duration-100 ease-in"
			leaveFrom="opacity-100 scale-100"
			leaveTo="opacity-0 scale-95"
		>
			<Popover.Panel
				tabIndex={-1}
				className="absolute text-black top-24 z-50 inset-x-0 p-2 transition transform origin-top-right xl:hidden"
			>
				<div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
					<div className="px-5 pt-4 flex items-center justify-between">
						<Popover.Button as={Link} to="/">
							<img
								className="h-8 w-auto"
								src={LogoBlack}
								alt="Polymerize logo"
							/>
						</Popover.Button>

						<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset">
							<X />
						</Popover.Button>
					</div>

					<div className="px-2 pt-2 pb-3 space-y-1">
						<MobileSubmenu
							label="header.navigation-items.1.title"
							sublinks={products}
						/>
						<Popover.Button as={Link} to="/connect/" className="py-3 flex px-6">
							{t("common.words.connect")}
						</Popover.Button>
						<MobileSubmenu
							label="header.navigation-items.2.title"
							sublinks={solutions}
						/>
						<MobileSubmenu
							label="header.navigation-items.3.title"
							sublinks={resources}
						/>
						<MobileSubmenu
							label="header.navigation-items.4.title"
							sublinks={company}
						/>
					</div>

					<div className="flex items-stretch">
						<a
							href="https://lab.polymerize.io/login"
							className="inline-block w-1/2 px-5 py-3 text-center font-medium text-theme-blue bg-gray-50 hover:bg-gray-100"
						>
							{t("common.cta.login")}
						</a>
						<button
							className="inline-block w-1/2 px-5 py-3 text-center font-medium bg-theme-blue text-gray-50 hover:bg-theme-blue-hover"
							onClick={() => window.requestADemo()}
						>
							{t("common.cta.demo")}
						</button>
					</div>
				</div>
			</Popover.Panel>
		</Transition>
	)
}
