import React, { useState, ChangeEvent, FormEvent } from "react"
import { Input, PrimaryBtn } from 'components/library/components'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";


const API_URL = `${process.env.GATSBY_BACKEND_API_URL_V1 ?? 'https://uat.polymerize.io/v1/data'}/subscribe_to_newsletter`

const nullData = {
    name: '',
    company: '',
    email: '',
}

export const NewsletterSmall = () => {
    const [data, setData] = useState(nullData)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { t } = useTranslation()

    function handleDataChange(e: ChangeEvent<HTMLInputElement>) {
        const { name, value } = e.target
        setData({ ...data, [name]: value })
    }

    async function handleNewsletterSubscribe(e: FormEvent) {
        e.preventDefault()
        try {
            setIsSubmitting(true)
            const response = await fetch(API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: data.email,
                    name: data.name,
                    company_name: data.company
                })
            })
            const result = await response.json()
            toast.success(t('common.messages.subscription-successful'), {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            console.log({ result })
            setData(nullData)
        } catch (err) {
            toast.error(t('common.messages.internal-server-error'), {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            console.log({ err })
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <div className='flex flex-col gap-3'>
            <ToastContainer />
            <h3 className='text-2xl text-[#253858]'>
                {t('newsletter.main.title')}
            </h3>
            <p>
                {t('newsletter.main.desc')}
            </p>
            <form onSubmit={handleNewsletterSubscribe} className='flex flex-col gap-2'>
                <Input
                    name="name"
                    value={data.name}
                    onChange={handleDataChange}
                    className='px-3 py-2 text-sm'
                    placeholder={t('newsletter.main.form.name')}
                    required
                />
                <Input
                    name="company"
                    value={data.company}
                    onChange={handleDataChange}
                    className='px-3 py-2 text-sm'
                    placeholder={t('newsletter.main.form.company')}
                    required
                />
                <Input
                    name="email"
                    type="email"
                    value={data.email}
                    onChange={handleDataChange}
                    className='px-3 py-2 text-sm'
                    placeholder={t('newsletter.main.form.email')}
                    required
                />
                <PrimaryBtn
                    type="submit"
                    disabled={isSubmitting}
                    className='bg-theme-orange hover:bg-theme-orange/80 disabled:bg-neutral-200 disabled:text-neutral-500 py-2 px-3'
                >
                    {isSubmitting ? t('common.cta.subscribing') + '...' : t('common.cta.subscribe')}
                </PrimaryBtn>
            </form>
        </div>
    )
}
