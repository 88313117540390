import React, { Fragment, useEffect, useState } from "react"
import { Listbox, Transition } from "@headlessui/react"
import { Check, ChevronDown, ChevronUp } from "react-feather"
import { Link } from "gatsby"
import { toLowerTraincaseInverse } from "utils/general"
import { Container } from "components/library/components"
import { useTranslation } from "gatsby-plugin-react-i18next"

type P = {
	currentCategory: string
	categories: string[]
}
export const Subheader = ({ currentCategory, categories }: P) => {
	const [isScrolled, setIsScrolled] = useState(false)
	const { t } = useTranslation()

	function handleScroll() {
		window.scrollY > 20 ? setIsScrolled(true) : setIsScrolled(false)
	}

	useEffect(() => {
		window.addEventListener("scroll", handleScroll)
		return () => window.removeEventListener("scroll", handleScroll)
	}, [])

	if (!categories?.length) return null

	return (
		<section
			className={`w-full bg-white sticky top-0 z-20 left-0 ${
				isScrolled ? "shadow-xl" : ""
			}`}
		>
			<Container className="flex justify-between items-center py-4 relative">
				<Link to="/" className="text-gray-300 text-2xl sm:text-3xl">
					<div>{t("common.words.blog")}</div>
				</Link>

				<div className="hidden lg:flex gap-10 items-center">
					{categories.map((category) => {
						return (
							<Link
								key={category}
								to={`/category/${category}`}
								className={`${
									currentCategory === category
										? "text-theme-blue font-semibold"
										: ""
								} `}
							>
								{category.includes("/")
									? toLowerTraincaseInverse(category).toUpperCase()
									: toLowerTraincaseInverse(category)}
							</Link>
						)
					})}
				</div>

				<div className="lg:hidden">
					<Listbox
						value={currentCategory}
						onChange={() => console.log("hello")}
					>
						{({ open }) => (
							<>
								<div>
									<Listbox.Button className="bg-white text-gray-700 flex items-center gap-2">
										<span>
											{currentCategory
												? currentCategory.includes("/")
													? toLowerTraincaseInverse(
															currentCategory
													  ).toUpperCase()
													: toLowerTraincaseInverse(currentCategory)
												: t("common.words.categories")}
										</span>
										<span>
											{open ? (
												<ChevronUp size={20} />
											) : (
												<ChevronDown size={20} />
											)}
										</span>
									</Listbox.Button>

									<Transition
										show={open}
										as={Fragment}
										leave="transition ease-in duration-100"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<Listbox.Options className="absolute left-[2vw] w-[96vw] z-10 top-[90%] bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
											{categories.map((cat) => (
												<Link key={cat} to={`/category/${cat}`}>
													<Listbox.Option
														className={({ active }) =>
															`${
																active
																	? "text-white bg-theme-blue"
																	: "text-gray-700"
															}  select-none flex gap-3 justify-between items-center py-2 pl-3 pr-9`
														}
														value={cat}
													>
														{({ selected, active }) => (
															<>
																<span
																	className={`${
																		selected ? "font-semibold" : "font-normal"
																	} block truncate`}
																>
																	{cat.includes("/")
																		? toLowerTraincaseInverse(cat).toUpperCase()
																		: toLowerTraincaseInverse(cat)}
																</span>

																{selected && (
																	<Check
																		className={`h-5 w-5 ${
																			active ? "text-white" : "text-theme-blue"
																		}`}
																		aria-hidden="true"
																	/>
																)}
															</>
														)}
													</Listbox.Option>
												</Link>
											))}
										</Listbox.Options>
									</Transition>
								</div>
							</>
						)}
					</Listbox>
				</div>
			</Container>
		</section>
	)
}
