import React from "react";
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import { getSiteBaseUrl } from "utils/urls";

type P = {
    logo: string
}

export const Logo = ({ logo }: P) => {
	const { language } = useI18next()

    return (
        <Link to={`${getSiteBaseUrl(language)}`} className='shrink-0'>
            <img
                alt="Polymerize logo"
                className="h-8 w-auto sm:h-10"
                src={logo}
            />
        </Link>
    )
};
