import React from "react";
import LogoBlack from "images/Original-Black.png";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { Popover } from "@headlessui/react";
import { Menu } from "react-feather";
import {
  PrimaryBtn,
  PrimaryAlternativeBtn,
  Container,
} from "components/library/components";
import { LanguageSelector } from "./header-language-selector";
import { Logo } from "./header-logo";
import { MobileMenu } from "./header-mobile-menu";
import { Submenu } from "./header-submenu";
import { TranslationKey } from "types/translation";
import { Icon } from "types/components";
import { OverviewIcon } from "components/library/icons/header/overview-icon";
import { PolymerizeLabsIcon } from "components/library/icons/header/polymerize-labs";
import { PolymerizeConnectIcon } from "components/library/icons/header/polymerize-connect";
import { MaterialInformaticsIcon } from "components/library/icons/header/material-informatics-icon";
import { RndOperationsIcon } from "components/library/icons/header/rnd-operations-icon";
import { TechnologyScoutsIcon } from "components/library/icons/header/technology-scouts-icon";
import { ResearcherIcon } from "components/library/icons/header/researcher-icon";
import { MaterialStartupIcon } from "components/library/icons/header/material-startup-icon";
import { SupportIcon } from "components/library/icons/header/support-icon";
import { FaqIcon } from "components/library/icons/header/faq-icon";
import { BlogIcon } from "components/library/icons/header/blog-icon";
import { BookIcon } from "components/library/icons/header/book-icon";
import { MediaIcon } from "components/library/icons/header/media-icon";
import { AboutPolymerizeIcon } from "components/library/icons/header/about-polymerize";
import { PricingIcon } from "components/library/icons/header/pricing-icon";
import { CareersIcon } from "components/library/icons/header/careers-icon";
import { EventsIcon } from "components/library/icons/header/events-icon";
import { SecurityIcon } from "components/library/icons/header/security-icon";
import { ContactIcon } from "components/library/icons/header/contact-icon";
import { BLOG_BASE_URL } from "variables";
import { ApplicationsIcon } from "components/library/icons/header/applications-icon";
import { NewsroomIcon } from "components/library/icons/header/newsroomIcon";
import { SolutionsIcon } from 'components/library/icons/header/solutions-page-icon'

export type Link = {
  name: TranslationKey;
  href: string;
  newWindow?: boolean;
  icon: Icon;
};

const products: Link[] = [
  {
    name: "header.navigation-items.1.subitems.1",
    href: "/products/overview/",
    icon: OverviewIcon,
  },
  {
    name: "header.navigation-items.1.subitems.6",
    href: "/polymerize-labs/",
    icon: PolymerizeLabsIcon,
  },
  {
    name: "header.navigation-items.1.subitems.7",
    href: "/connect/",
    icon: PolymerizeConnectIcon,
  },
  {
    name: "header.navigation-items.1.subitems.2",
    href: "/products/material-informatics/",
    icon: MaterialInformaticsIcon,
  },
  // {
  // 	name: "header.navigation-items.1.subitems.3",
  // 	href: "/products/ai/",
  // 	icon: AIEngineIcon,
  // },
  // {
  // 	name: "header.navigation-items.1.subitems.4",
  // 	href: "/products/data-management/",
  // 	icon: DataManagementIcon,
  // },
  // {
  // 	name: "header.navigation-items.1.subitems.5",
  // 	href: "/products/doe/",
  // 	icon: DOEIcon,
  // },
];

const solutions: Link[] = [
  {
    name: 'header.navigation-items.1.subitems.1',
    href: '/solutions/overview',
    icon: SolutionsIcon,
	},
  {
    name: "header.navigation-items.2.subitems.1",
    href: "/solutions/research-and-development/",
    icon: RndOperationsIcon,
  },
  {
    name: "header.navigation-items.2.subitems.2",
    href: "/solutions/technology-scouts/",
    icon: TechnologyScoutsIcon,
  },
  {
    name: "header.navigation-items.2.subitems.3",
    href: "/solutions/researchers/",
    icon: ResearcherIcon,
  },
  {
    name: "header.navigation-items.2.subitems.4",
    href: "/solutions/polymerize-for-startups/",
    icon: MaterialStartupIcon,
  },
];

const resources: Link[] = [
  {
    name: 'common.words.applications',
    href: '/applications/',
    icon: ApplicationsIcon,
	},
  {
    name: 'header.navigation-items.3.subitems.4',
    href: '/case-studies-n-white-papers/',
    icon: BookIcon
  },
  {
    name: 'header.navigation-items.3.subitems.3',
    href: BLOG_BASE_URL,
    icon: BlogIcon,
    newWindow: true
  },
  {
    name: 'common.words.newsletter',
    href: '/newsletter/',
    icon: MediaIcon,
	},
  {
    name: 'header.navigation-items.4.subitems.4',
    href: '/newsroom/',
    icon: NewsroomIcon
  },
  {
    name: 'header.navigation-items.3.subitems.1',
    href: '/support/',
    icon: SupportIcon
  },
  {
    name: 'header.navigation-items.3.subitems.2',
    href: '/faqs/',
    icon: FaqIcon
  },
  // {
  //   name: 'Webinar',
  //   href: '/webinar/',
  //   icon: Users
  // }
]

const company: Link[] = [
  {
    name: "header.navigation-items.4.subitems.1",
    href: "/about-us/",
    icon: AboutPolymerizeIcon,
  },
  {
    name: "common.words.pricing",
    href: "/pricing/",
    icon: PricingIcon,
  },
  {
    name: "header.navigation-items.4.subitems.2",
    href: "/careers/",
    icon: CareersIcon,
  },
  {
    name: "header.navigation-items.4.subitems.5",
    href: "/events/",
    icon: EventsIcon,
  },
  {
    name: "common.words.security",
    href: "/security/",
    icon: SecurityIcon,
  },
  {
    name: "header.navigation-items.4.subitems.3",
    href: "/contact-us/",
    icon: ContactIcon,
  },
];

const HeaderBlackState = {
  buttonVariant: "primary",
  logo: LogoBlack,
  color: "text-black",
};

const headerState = HeaderBlackState;

export const Header = () => {
  const { t } = useTranslation();
  // const { language } = useI18next();

  return (
    <header
      className={`${headerState.color} h-14 flex items-center lg:h-16 z-30`}
    >
      <Container>
        <Popover>
          <nav className="flex items-center gap-6 justify-between">
            <Logo logo={headerState.logo} />

            <div className="flex gap-4 xl:hidden">
              <LanguageSelector />
              <Popover.Button>
                <Menu />
              </Popover.Button>
            </div>

            <div className="hidden xl:flex gap-5 items-center">
              <Submenu
                isScrolled={true}
                label="header.navigation-items.1.title"
                sublinks={products}
              />
              {/* <Link
                to={`${
                  language === "en" ? WEBSITE_BASE_URL : WEBSITE_BASE_URL_JP
                }/connect/`}
                className={`font-semibold  ${
                  true
                    ? "text-orange-500 hover:text-theme-blue"
                    : "hover:text-[#FF9E50]"
                }`}
              >
                {t("common.words.connect")}
              </Link> */}
              <Submenu
                isScrolled={true}
                label="header.navigation-items.2.title"
                sublinks={solutions}
              />
              <Submenu
                isScrolled={true}
                label="header.navigation-items.3.title"
                sublinks={resources}
              />
              <Submenu
                isScrolled={true}
                label="header.navigation-items.4.title"
                sublinks={company}
              />
              <a
                href="https://lab.polymerize.io/login"
                className={`flex flex-col justify-center ${
                  true
                    ? "hover:text-theme-blue hover:font-semibold"
                    : "hover:font-semibold"
                }`}
              >
                {t("common.cta.login")}
              </a>

              <div className="rounded-sm drop-shadow-custom">
                {headerState.buttonVariant === "primary" ? (
                  <PrimaryBtn
                    className={`text-white`}
                    onClick={() => window.requestADemo()}
                  >
                    {t("common.cta.demo")}
                  </PrimaryBtn>
                ) : (
                  <PrimaryAlternativeBtn
                    className="ring-theme-grey"
                    onClick={() => window.requestADemo()}
                  >
                    {t("common.cta.demo")}
                  </PrimaryAlternativeBtn>
                )}
              </div>
              <LanguageSelector />
            </div>
          </nav>

          <MobileMenu
            products={products}
            solutions={solutions}
            resources={resources}
            company={company}
          />
        </Popover>
      </Container>
    </header>
  );
};
