import React from "react"
import { Icon } from "types/components"

export const ResearcherIcon: Icon = ({ size = 24, color, ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			width={size}
			height={size}
			viewBox="0 0 20 20"
			{...props}
		>
			<path
				fill="url(#paint0_linear_6348_40020)"
				d="M10.024 2.715c-1.134 0-2.275.014-3.409-.007-.897-.013-1.1-.303-1.086-1.45.006-.993.216-1.243 1.113-1.25C8.917-.005 11.185.002 13.46.002c.371 0 .75-.034.729.526-.02.574-.432.473-.776.473-2.08.006-4.158 0-6.237.013-.25 0-.662-.196-.642.358.02.547.432.324.675.324 2.08.013 4.158.007 6.238.007.35 0 .756-.095.749.492-.007.554-.392.513-.756.513-1.141.007-2.282.007-3.416.007z"
			></path>
			<path
				fill="url(#paint1_linear_6348_40020)"
				d="M19.4 17.64c-.128-.21-.283-.412-.432-.608-1.66-2.146-3.307-4.3-4.995-6.426-.371-.466-.513-.931-.5-1.512.034-1.58.027-3.159-.006-4.738-.007-.365.297-.986-.466-1.006-.85-.02-.493.655-.506 1.033-.04 1.215.06 2.443-.034 3.658-.122 1.478.121 2.788 1.242 3.84.027.028 0 .102 0 .17H5.846c.405-.534.702-.966 1.04-1.364.242-.29.357-.587.357-.979-.013-1.883-.007-3.766-.007-5.656 0-.358.068-.743-.513-.722-.58.013-.486.418-.486.762-.006 1.722-.013 3.436.007 5.157a1.69 1.69 0 01-.378 1.141c-1.809 2.342-3.604 4.705-5.4 7.06-.392.514-.621 1.074-.344 1.702.283.641.897.776 1.518.837.19.02.385.007.581.007 5.157-.027 10.314-.061 15.465-.088.195 0 .391 0 .58-.014 1.296-.121 1.81-1.134 1.134-2.254zm-.736.992c-.148.344-.52.257-.81.257-2.66.006-5.319.006-7.978.006v.061c-2.633 0-5.265 0-7.898-.007-.324 0-.736.095-.905-.25-.175-.344.162-.614.351-.87 1.067-1.411 2.16-2.808 3.22-4.232.237-.318.493-.453.898-.446 2.882.014 5.765.014 8.647 0 .364 0 .614.101.837.398 1.1 1.458 2.228 2.896 3.341 4.347.162.223.412.453.297.736z"
			></path>
			<path
				fill="url(#paint2_linear_6348_40020)"
				d="M7.891 15.689c-.304-.034-.513-.155-.547-.439-.033-.317.115-.533.446-.567.297-.027.472.162.52.419.06.33-.183.479-.419.587z"
			></path>
			<path
				fill="url(#paint3_linear_6348_40020)"
				d="M5.191 17.498c-.02.297-.202.48-.459.493-.304.013-.526-.183-.52-.5.007-.317.21-.5.534-.472.283.02.418.216.445.479z"
			></path>
			<path
				fill="url(#paint4_linear_6348_40020)"
				d="M14.189 15.689c-.29-.068-.506-.23-.486-.547.014-.276.196-.445.486-.452.29-.007.466.169.493.439.027.324-.196.479-.493.56z"
			></path>
			<path
				fill="url(#paint5_linear_6348_40020)"
				d="M16.187 17.775c-.054.371-.263.54-.567.506-.256-.034-.452-.216-.425-.506.027-.331.243-.48.553-.452.29.02.412.222.439.452z"
			></path>
			<path
				fill="url(#paint6_linear_6348_40020)"
				d="M11.26 16.998c-.028.297-.196.5-.466.5-.324.007-.513-.21-.493-.54.02-.284.196-.459.5-.439.296.014.425.21.458.48z"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_6348_40020"
					x1="5.528"
					x2="14.196"
					y1="1.36"
					y2="1.36"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color ?? "#1753E2"}></stop>
					<stop offset="1" stopColor={color ?? "#F39244"}></stop>
				</linearGradient>
				<linearGradient
					id="paint1_linear_6348_40020"
					x1="0"
					x2="19.698"
					y1="11.664"
					y2="11.664"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color ?? "#1753E2"}></stop>
					<stop offset="1" stopColor={color ?? "#F39244"}></stop>
				</linearGradient>
				<linearGradient
					id="paint2_linear_6348_40020"
					x1="4.212"
					x2="16.187"
					y1="16.483"
					y2="16.483"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color ?? "#1753E2"}></stop>
					<stop offset="1" stopColor={color ?? "#F39244"}></stop>
				</linearGradient>
				<linearGradient
					id="paint3_linear_6348_40020"
					x1="4.212"
					x2="16.187"
					y1="16.483"
					y2="16.483"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color ?? "#1753E2"}></stop>
					<stop offset="1" stopColor={color ?? "#F39244"}></stop>
				</linearGradient>
				<linearGradient
					id="paint4_linear_6348_40020"
					x1="4.212"
					x2="16.187"
					y1="16.483"
					y2="16.483"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color ?? "#1753E2"}></stop>
					<stop offset="1" stopColor={color ?? "#F39244"}></stop>
				</linearGradient>
				<linearGradient
					id="paint5_linear_6348_40020"
					x1="4.212"
					x2="16.187"
					y1="16.483"
					y2="16.483"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color ?? "#1753E2"}></stop>
					<stop offset="1" stopColor={color ?? "#F39244"}></stop>
				</linearGradient>
				<linearGradient
					id="paint6_linear_6348_40020"
					x1="4.212"
					x2="16.187"
					y1="16.483"
					y2="16.483"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={color ?? "#1753E2"}></stop>
					<stop offset="1" stopColor={color ?? "#F39244"}></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
